import React from 'react';
import SearchIcon from '../../assets/Search.svg';

const SearchInput = ({ customClass, placeholder, value, onChange }) => (
  <div className={`search-wrapper ${customClass || ''}`}>
    <img src={SearchIcon} className="icon" alt="icon" />
    <input
      value={value}
      className="input search__input"
      placeholder={placeholder || 'Search'}
      onChange={(event) => onChange(event.target.value)}
    />
  </div>
);

export default SearchInput;
