import React from 'react';

const RegisterValidators = ({ active }) => {
  const fillColor = active ? '#329D9C' : '#CECECE';
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3623 14.8076C12.3685 14.1391 13.1329 13.1645 13.5422 12.0279C13.9515 10.8913 13.984 9.65306 13.6349 8.49654C13.2858 7.34002 12.5736 6.32661 11.6038 5.60631C10.634 4.886 9.45798 4.49707 8.24991 4.49707C7.04184 4.49707 5.86587 4.886 4.89603 5.60631C3.92619 6.32661 3.21402 7.34002 2.86491 8.49654C2.5158 9.65306 2.5483 10.8913 2.95761 12.0279C3.36692 13.1645 4.13128 14.1391 5.13757 14.8076C3.42214 15.4404 1.93952 16.5796 0.88624 18.0741C0.807163 18.1865 0.760455 18.3184 0.751212 18.4554C0.741969 18.5925 0.770545 18.7294 0.833824 18.8514C0.897103 18.9733 0.992653 19.0755 1.11005 19.1468C1.22745 19.2182 1.36218 19.2559 1.49955 19.2559L15 19.2554C15.1373 19.2554 15.2721 19.2177 15.3895 19.1463C15.5069 19.075 15.6024 18.9727 15.6657 18.8508C15.7289 18.7289 15.7575 18.5919 15.7482 18.4548C15.739 18.3178 15.6922 18.1859 15.6131 18.0736C14.5599 16.5793 13.0774 15.4403 11.3623 14.8076Z"
        fill={fillColor}
      />
      <path
        d="M23.2558 18.0736C22.2026 16.5793 20.7201 15.4403 19.0049 14.8076C20.1201 14.0652 20.9337 12.9492 21.2994 11.6604C21.665 10.3716 21.5587 8.99465 20.9995 7.77725C20.4404 6.55985 19.4651 5.58198 18.2492 5.01962C17.0332 4.45725 15.6565 4.3473 14.3668 4.70957C14.2497 4.7425 14.1424 4.80341 14.0541 4.88706C13.9657 4.97071 13.8991 5.07457 13.8599 5.18971C13.8207 5.30484 13.81 5.42779 13.8289 5.54795C13.8478 5.6681 13.8956 5.78187 13.9682 5.87943C14.8161 7.0183 15.3035 8.38495 15.3676 9.8033C15.4318 11.2217 15.0698 12.6267 14.3283 13.8375C14.2304 13.9972 14.1958 14.1877 14.2312 14.3717C14.2667 14.5556 14.3697 14.7196 14.52 14.8314C14.8044 15.043 15.078 15.2687 15.3397 15.5077C15.3533 15.5224 15.3669 15.5371 15.3818 15.5509C16.3829 16.4763 17.1942 17.5878 17.7705 18.8232C17.8308 18.9526 17.9269 19.062 18.0473 19.1386C18.1676 19.2152 18.3074 19.2559 18.4501 19.2559L22.6427 19.2554C22.78 19.2554 22.9148 19.2177 23.0321 19.1463C23.1495 19.075 23.245 18.9727 23.3083 18.8508C23.3716 18.7289 23.4001 18.5919 23.3909 18.4549C23.3816 18.3178 23.3349 18.1859 23.2558 18.0736L23.2558 18.0736Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default RegisterValidators;
