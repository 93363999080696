import * as nearAPI from 'near-api-js';
import getConfig from '../config';
import { getWallet } from '../utils/near-utils';

export const { networkId, nodeUrl, contractName, contractMethods, GAS, ipfsURL } = getConfig();

export const {
  utils: {
    format: { formatNearAmount, parseNearAmount },
  },
} = nearAPI;

export const initNear = () => async ({ update }) => {
  const { near, wallet, contractAccount } = await getWallet();

  wallet.signIn = () => {
    wallet.requestSignIn(contractName[0]);
  };

  const signOut = wallet.signOut;
  wallet.signOut = () => {
    signOut.call(wallet);
    update('wallet.signedIn', false);
    update('', { account: null });
    localStorage.clear();
  };
  wallet.changeWallet = () => {
    signOut.call(wallet);
    localStorage.clear();
    wallet.requestSignIn(contractName[0]);
  };
  wallet.signedIn = wallet.isSignedIn();

  let account;
  if (wallet.signedIn) {
    account = wallet.account();
    wallet.balance = formatNearAmount((await wallet.account().getAccountBalance()).available, 2);
    await update('', { near, wallet, contractAccount, account });
  }

  await update('', { near, wallet, contractAccount, account });
  await update('loadedKeys', true);
};

export const updateWallet = () => async ({ update, getState }) => {
  const { wallet } = await getState();
  wallet.balance = formatNearAmount((await wallet.account().getAccountBalance()).available, 2);
  await update('', { wallet });
  await update('loadedKeys', true);
};
