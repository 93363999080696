import React from 'react';

import ApproveCarbon from '../../../assets/tabs/ApproveCarbon';
import ApproveOrg from '../../../assets/tabs/ApproveOrg';
import ProjectsOnProd from '../../../assets/tabs/ProjectsOnProd';
import RegisterValidators from '../../../assets/tabs/RegisterValidators';
import PermissionsIcon from '../../../assets/tabs/Permissions';
import { navigationTabs } from '../../../utils/constants';
import Settings from '../../../assets/tabs/Settings';

const TabImg = ({ name, active }) => {
  const {
    ApproveOrganizations,
    ApproveCarbonProjects,
    RegisterVal,
    Projects,
    Permissions,
    ProjectSettings,
  } = navigationTabs;
  switch (name) {
    case ApproveOrganizations:
      return <ApproveOrg active={active} />;
    case ApproveCarbonProjects:
      return <ApproveCarbon active={active} />;
    case RegisterVal:
      return <RegisterValidators active={active} />;
    case Projects:
      return <ProjectsOnProd active={active} />;
    case Permissions:
      return <PermissionsIcon active={active} />;
    case ProjectSettings:
      return <Settings active={active} />;

    default:
      return (<></>);
  }
};

export default TabImg;
