import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputElem = ({
  label = '',
  value = '',
  disabled = false,
  onChange = () => {},
}) => (
  <div className="phone">
    {label && <p className="phone__label">{label}</p>}
    <PhoneInput
      country="us"
      value={value}
      disabled={disabled}
      onChange={(phone) => onChange(phone)}
    />
  </div>
);

export default PhoneInputElem;
