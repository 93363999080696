import React from 'react';

const Edit = ({ active }) => {
  const fillColor = active ? '#329D9C' : '#D5D9E7';
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.41999 20.5787C4.13948 20.5782 3.87206 20.4599 3.68299 20.2527C3.49044 20.0471 3.39476 19.7692 3.41999 19.4887L3.66499 16.7947L14.983 5.48066L18.52 9.01666L7.20499 20.3297L4.51099 20.5747C4.47999 20.5777 4.44899 20.5787 4.41999 20.5787ZM19.226 8.30966L15.69 4.77366L17.811 2.65266C17.9986 2.46488 18.2531 2.35938 18.5185 2.35938C18.7839 2.35938 19.0384 2.46488 19.226 2.65266L21.347 4.77366C21.5348 4.96123 21.6403 5.21575 21.6403 5.48116C21.6403 5.74657 21.5348 6.00109 21.347 6.18866L19.227 8.30866L19.226 8.30966Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default Edit;
