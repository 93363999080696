/* eslint-disable no-undef */
const { decodeUTF8 } = require('tweetnacl-util');
const tweetnacl = require('tweetnacl');
const borsh = require('borsh');
const bs58 = require('bs58');

const toBuffer = (arr) => {
  if (Buffer.isBuffer(arr)) {
    return arr;
  }

  if (arr instanceof Uint8Array) {
    return Buffer.from(arr.buffer, arr.byteOffset, arr.byteLength);
  }

  return Buffer.from(arr);
};
const encrypt = ({ account, secret }) => {
  const key = secret && secret.split(':')[1];
  const keyPair = tweetnacl.sign.keyPair.fromSecretKey(borsh.baseDecode(key === null ? '' : key));
  const signature = tweetnacl.sign.detached(decodeUTF8(account), keyPair.secretKey);
  return {
    account_id: account,
    hash: toBuffer(signature).toString('base64'),
    public_key: bs58.encode(toBuffer(keyPair.publicKey)),
  };
};
export const encryptFunc = function (obj) {
  return encrypt(obj);
};
