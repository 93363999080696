export const convertToMilliseconds = (d, h, m) => {
  d = +d * 24 * 60 * 60 * 1000;
  h = +h * 60 * 60 * 1000;
  m = +m * 60 * 1000;
  return d + h + m;
};
export const nanoToMicro = (timestamp) => Math.floor(timestamp * 1e6);

export const convertMillisecondsToDHM = (t) => {
  const cd = 24 * 60 * 60 * 1000;
  const ch = 60 * 60 * 1000;
  let d = Math.floor(t / cd);
  let h = Math.floor((t - d * cd) / ch);
  let m = Math.round((t - d * cd - h * ch) / 60000);
  if (m === 60) {
    h++;
    m = 0;
  }
  if (h === 24) {
    d++;
    h = 0;
  }
  return { days: d, hours: h, minutes: m };
};
