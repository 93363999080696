import React from 'react';
import FieldText from '../../generic/FieldText';
import CustomCheckbox from '../../../../generic/CustomCheckbox';
import Button from '../../../../generic/Button';

const LandInfo = ({ step, setStep, data }) => {
  if (step !== 3) {
    return null;
  }
  const {
    whoOwnsTheLand = '',
    landUseOfProjectZone = '',
    wasProjectZoneCleared = '',
    howLandWasCleared = '',
    currentLandUse = '',
    commerciallyUsed = '',
    howZoneIsUsedByCommunities = '',
    involvementInLivelihoodsPrograms = '',
    engagedInAlternativeOptions = '',
    landUseIfZoneNotImplemented = '',
    isLocatedOnPeatlandsOrWetlands = '',
    includeExistingScatteredTrees = '',
  } = data?.carbonFormInfo?.landInfo;

  return (
    <div className="eval__block">
      <FieldText
        label="3.1 Who owns the land? If the land is leased, are you in possession of a convention?"
        value={whoOwnsTheLand}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <FieldText
        label="3.2 What was the land use of the project zone in the past 10 years prior to the project start?"
        value={landUseOfProjectZone}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <div className="eval__gen-checkboxs">
        <span>3.3 Was the project zone cleared and deforested in the past 10 years prior to the project start?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(wasProjectZoneCleared) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(wasProjectZoneCleared)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(wasProjectZoneCleared) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(wasProjectZoneCleared)}
        />
      </div>
      <FieldText
        label="3.4 If yes, please explain when why and how the land was cleared."
        value={howLandWasCleared}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <FieldText
        label="3.5 What is the land use of the project zone right before the start of the project?"
        value={currentLandUse}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <div className="eval__gen-checkboxs">
        <span>3.6 Are/were local communities commercially using the project zone prior to the project start?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(commerciallyUsed) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(commerciallyUsed)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(commerciallyUsed) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(commerciallyUsed)}
        />
      </div>
      <FieldText
        label="3.7 If yes, please explain how the project zone was/is used by local communities."
        value={howZoneIsUsedByCommunities}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <FieldText
        label="3.8 Does the project include an alternative livelihoods program? If yes, please explain the involvement of the local communities in the programs."
        value={involvementInLivelihoodsPrograms}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <FieldText
        label="3.9 If yes, has the local community already engaged in the alternative livelihood options and how?"
        value={engagedInAlternativeOptions}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <FieldText
        label="3.10 What would be the land use in the project zone if the project  would not be implemented? The projected scenario should be compatible with current laws and regulations."
        value={landUseIfZoneNotImplemented}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <FieldText
        label="3.11 Is the project located on peatlands / wetlands (tidal wetlands included) and could result in the manipulation of the water table?"
        value={isLocatedOnPeatlandsOrWetlands}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <div className="eval__gen-checkboxs">
        <span>3.12 Prior to project start, did the project zone include previously existing scattered trees?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(includeExistingScatteredTrees) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(includeExistingScatteredTrees)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(includeExistingScatteredTrees) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(includeExistingScatteredTrees)}
        />
      </div>
      <div className="eval__wrapper-btns">
        <Button className="cancel" onClick={() => setStep(2)}>Back </Button>
        <Button onClick={() => setStep(4)}>Next</Button>
      </div>
    </div>
  );
};

export default LandInfo;
