import React, { useState } from 'react';
import CustomInput from '../../../generic/CustomInput';
import CustomCheckbox from '../../../generic/CustomCheckbox';
import CustomSelect from '../../../generic/CustomSelect';
import Button from '../../../generic/Button';

const EditableElement = ({ label, value, typeEdit, change, name, changeCheckbox, checkBoxValue, titleCheckbox, options, btnLabel, ccCInput = '' }) => {
  const [edit, setEdit] = useState(true);
  const renderEditElement = () => {
    switch (typeEdit) {
      case 'input':
        return (
          <CustomInput
            placeholder="Type here"
            onChange={(e) => {
              change(e);
            }}
            label={label}
            customClassName={`edit-lec__input pre-full ${ccCInput}`}
            name={name}
            value={value}
            onlyNumber
            disabled={edit}
          />
        );
      case 'inputWithCheck':
        return (
          <div className="edit-lec__block-w-check">
            <CustomInput
              placeholder="Type here"
              onChange={(e) => {
                change(e);
              }}
              customClassName={`edit-lec__input pre-full ${ccCInput}`}
              name={name}
              value={value}
              onlyNumber
              disabled={edit}
              label={label}
            />
            <CustomCheckbox
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  changeCheckbox(e);
                }
              }}
              value={checkBoxValue}
              title={titleCheckbox}
              description=""
              disabled={edit}
              key="yes"
              customClass="edit-lec__checkbox"
            />
          </div>
        );
      case 'select':
        return (
          <CustomSelect
            placeholder="Сhoose the forest type"
            change={(e) => {
              change(e);
            }}
            customClassName="edit-lec__carbons-select mb "
            optionArray={options}
            withCheck
            disabled={edit}
            name={name}
            classSelect="edit-lec__carbons-select-field "
            label="Forest type"
            value={value}
          />
        );
      default:
        return <div />;
    }
  };
  return (
    <div className="edit-lec__component">
      {renderEditElement()}
      <div className="edit-lec__btn-component">
        <Button className={`edit-lec__btn  ${!edit ? 'active' : ''}`} onClick={() => setEdit(!edit)}>
          <span>
            {' '}
            {btnLabel}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default EditableElement;
