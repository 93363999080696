import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import Container from '../../components/Container';
import Menu from '../Menu/Menu';

const Layout = ({ children }) => (
  <Container>
    <Header />
    <div className="layout">
      <Menu />
      <div className="content">
        <div className="page">{children}</div>
      </div>
    </div>
  </Container>
);

Layout.propTypes = {
  children: PropTypes.element,
};

export default Layout;
