/* eslint-disable */
import React from 'react';


const TableItemValidator = ({ email, accountId,itemRef,isLast }) => {
    const ref = isLast ? { ref: itemRef } : {};

    return (
    <div className="valitator-item__container" {...ref}>
        <div className="valitator-item__box">
            <div className="valitator-item__60">
                <div className="valitator-item__main-text">{email}</div>
            </div>
            <div className="valitator-item__30">
                <div className="valitator-item__main-text overflow">{accountId}</div>
            </div>
        </div>
        {/* need to next task */}
        {/* <Button className="trash">
				<img src={Trash} />
			</Button> */}
    </div>)
};

export default TableItemValidator;
