import React from 'react';
import FieldText from '../../generic/FieldText';
import CustomCheckbox from '../../../../generic/CustomCheckbox';
import Button from '../../../../generic/Button';

const ProjectActivity = ({ step, setStep, data }) => {
  if (step !== 2) {
    return null;
  }
  const {
    generalPlanning = '',
    sitePreparationActivities = '',
    willZoneBeBurned = '',
    arePestWeedControlUsed = false,
    pestWeedProducts = [],
    seedlingsComeFrom = '',
    typeOfTreePlantingActivities = '',
    areFertilizersUsed = '',
    fertilizersProducts = [],
    soilDisturbanceHappen = '',
    isPruningPlanned = '',
    isThinningPlanned = '',
    treeSpeciesNumber = '',
    areExoticSpeciesPlanted = '',
    exoticSpeciesList = '',
    measuresPutInPlace = '',
    intendToDoWithForest = '',
    continueLightGroundMonitoring = false,
  } = data?.carbonFormInfo?.projectActivity;

  return (
    <div className="eval__block">
      <FieldText
        label="2.1 Describe the general planning of the project."
        value={generalPlanning}
        customClass="full  not-out eval__activity-field textarea"
      />
      <FieldText
        label="2.2 Describe the site preparation activities."
        value={sitePreparationActivities}
        customClass="full  not-out eval__activity-field textarea"
      />
      <FieldText
        label="2.3 Will the project zone be burned (for site preparation or as part of forest management) prior to the project start? If yes, please explain."
        value={willZoneBeBurned}
        customClass="full  not-out eval__activity-field textarea"
      />
      <div className="eval__gen-checkboxs">
        <span>2.4 Are pest and weed control products used?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(arePestWeedControlUsed) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(arePestWeedControlUsed)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(arePestWeedControlUsed) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(arePestWeedControlUsed)}
        />
      </div>
      {pestWeedProducts.length > 0 && (
        <>
          <span className="eval__activity-title">2.5 If yes, please detail the products, quantity (tonnes of product/ha) and frequency.</span>
          {pestWeedProducts.map((el) => (
            <div className="eval__activity-block" key={el?.productName + el?.tonnes}>
              <FieldText
                placeholder={!el?.productName && 'Product Name'}
                value={el?.productName}
                customClass="eval__activity-field projA"
              />
              <FieldText
                placeholder={!el?.tonnes && 'Tonnes of product/ha'}
                value={el?.tonnes}
                customClass="eval__activity-field projA"
              />
              <FieldText
                placeholder={!el?.frequency && 'Frequency'}
                value={el?.frequency?.label}
                customClass="eval__activity-field projA"
              />
              <FieldText
                placeholder={!el?.duration && 'Duration'}
                value={el?.duration?.label}
                customClass="eval__activity-field projA"
              />
            </div>
          ))}
        </>
      )}
      <FieldText
        label="2.6 Where do seedlings come from?"
        value={seedlingsComeFrom}
        customClass="full not-out eval__activity-field textarea"
      />
      <FieldText
        label="2.7 Describe the type of tree planting activities? How is the plantation / regeneration done? (manually, with machines, with drones, etc.)."
        value={typeOfTreePlantingActivities}
        customClass="full not-out eval__activity-field textarea"
      />
      <div className="eval__gen-checkboxs">
        <span>2.8 Are fertilizers used?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(areFertilizersUsed) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(areFertilizersUsed)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(areFertilizersUsed) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(areFertilizersUsed)}
        />
      </div>

      {fertilizersProducts.length > 0 && (
      <>
        <span className="eval__activity-title">2.9 If yes, please detail the products, quantity (tonnes of product/ha) and frequency.</span>
        {fertilizersProducts.map((el) => (
          <div className="eval__activity-block" key={el?.productName + el?.tonnes}>
            <FieldText
              placeholder={!el?.productName && 'Product Name'}
              value={el?.productName}
              customClass="eval__activity-field"
            />
            <FieldText
              placeholder={!el?.tonnes && 'Tonnes of product/ha'}
              value={el?.tonnes}
              customClass="eval__activity-field"
            />
            <FieldText
              placeholder={!el?.frequency && 'Frequency'}
              value={el?.frequency?.label}
              customClass="eval__activity-field"
            />
            <FieldText
              placeholder={!el?.duration && 'Duration'}
              value={el?.duration?.label}
              customClass="eval__activity-field"
            />
          </div>
        ))}
      </>
      )}
      <FieldText
        label="2.10 Will soil disturbance (soil compaction due to machines, etc.) happen on more than 10% of the project zone? If so, please explain why."
        value={soilDisturbanceHappen}
        customClass="full not-out eval__activity-field textarea"
      />
      <FieldText
        label="2.11 Is pruning planned? If yes, please explain."
        value={isPruningPlanned}
        customClass="full not-out eval__activity-field textarea"
      />
      <FieldText
        label="2.12 Is thinning planned? If yes, please explain."
        value={isThinningPlanned}
        customClass="full not-out eval__activity-field textarea"
      />
      <span className="eval__activity-title">2.13 How many tree species are you planting in the project zone?</span>
      <div className="eval__activity-block">
        <FieldText
          value={treeSpeciesNumber}
          customClass="half eval__activity-field"
        />
      </div>
      <div className="eval__gen-checkboxs">
        <span>2.14 Are exotic / non-native tree species planted? </span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(areExoticSpeciesPlanted) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(areExoticSpeciesPlanted)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(areExoticSpeciesPlanted) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(areExoticSpeciesPlanted)}
        />
      </div>
      <FieldText
        label="2.15 If yes, please list the non-native species."
        value={exoticSpeciesList}
        customClass="full not-out eval__activity-field textarea"
      />
      <FieldText
        label="2.16 What are the measures put in place to make sure the forest will still be in the ground in at least 40 years from now ?"
        value={measuresPutInPlace}
        customClass="full not-out eval__activity-field textarea"
      />
      <FieldText
        label="2.17 At the end of the project, what do you intend to do with the standing forest?"
        value={intendToDoWithForest}
        customClass="full not-out eval__activity-field textarea"
      />
      <div className="eval__gen-checkboxs">
        <span>2.18 OFP will provide financial support for forest conservation (once the forest is mature). Would you agree on continuing a light ground monitoring to ensure the forest&apos;s ongoing conservation?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(continueLightGroundMonitoring) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(continueLightGroundMonitoring)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(continueLightGroundMonitoring) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(continueLightGroundMonitoring)}
        />
      </div>
      <div className="eval__wrapper-btns">
        <Button className="cancel" onClick={() => setStep(1)}>Back </Button>
        <Button onClick={() => setStep(3)}>Next</Button>
      </div>
    </div>
  );
};

export default ProjectActivity;
