export const runtimeMappings = {
  shortRegion: {
    type: 'keyword',
    script: `
      String region = params['_source']['region'];

      Pattern regexp1 = /[0-9\\/\\.!@#$%^&*,]+/;
      Pattern regexp2 = /\\s{2,}/;
      Pattern regexp3 = /,/;

      String tempString1 = regexp1.matcher(region).replaceAll(' ').trim();
      String tempString2 = regexp2.matcher(tempString1).replaceAll(',');
      String[] arr = regexp3.split(tempString2);

      String country = arr[arr.length - 1];
      emit(country);
    `,
  },
  parsedSquare: {
    type: 'double',
    script: `
      double sq = Double.parseDouble(params['_source']['square']);
      emit(sq);
    `,
  },
  currentDate: {
    type: 'long',
    script: `
      long currentDate = (long)(new Date().getTime() * 1000000);
      // long currentDate = 1661685342899365138L;
      emit(currentDate);
    `,
  },
  isStagesFinished: {
    type: 'boolean',
    script: `
      boolean isStagesFinished = false;
      def stages = params['_source']['stages'];
      if (stages !== null) {
        long currentDate = (long)(new Date().getTime() * 1000000);
        def lastStage = stages[stages.length - 1];
        long endTime = Long.parseLong(lastStage.ends_at);
        if (currentDate > endTime) {
          isStagesFinished = true;
        }
      }
      emit(isStagesFinished);
    `,
  },
  isProjectFinished: {
    type: 'boolean',
    script: `
      boolean isProjectFinished = false;
      def stages = params['_source']['stages'];
      if (stages !== null) {
        long currentDate = (long)(new Date().getTime() * 1000000);
        def lastStage = stages[stages.length - 1];
        def lastStagePeriods = lastStage.periods;
        def lastPeriod = lastStagePeriods[lastStagePeriods.length - 1];
        long endPeriod = Long.parseLong(lastPeriod.ends_at);
  
        if (currentDate > endPeriod) {
          isProjectFinished = true;
        }
      }
      emit(isProjectFinished);
    `,
  },
  currentStage: {
    type: 'long',
    script: `
      long res = -1;
      boolean isStagesFinished = doc['isStagesFinished'].value;
      boolean isProjectFinished = doc['isProjectFinished'].value;
      String projectStatus = params['_source']['status'];
      if (projectStatus === 'register' || projectStatus === 'closed' || projectStatus === 'failed' ) {
        emit(res);
        return;
      }

      def stages = params['_source']['stages'];
      if (stages === null) {
        emit(res);
        return;
      }

      if (isStagesFinished && !isProjectFinished) {
        emit(stages.length - 1 );
        return;
      }
  
      long currentDate = (long)(new Date().getTime() * 1000000);
      for (int i = 0; i < stages.length; i++){
        long startStage = Long.parseLong(stages[i]['starts_at']);
        long endStage = Long.parseLong(stages[i]['ends_at']);
        if (currentDate >= startStage && currentDate <= endStage) {
          res = (long)(i);
          break;
        }
      }
      emit(res);
    `,
  },
  // voteStageFinish: {
  //   type: 'string',
  //   script: `
  //     def resultArray = new ArrayList();
  //     long currentDate = (long)(new Date().getTime() * 1000000);
  //           def stages = params['_source']['stages'];
  //
  //       for (int i = 0; i < stages.length; i++){
  //       long endStage = Long.parseLong(stages[i]['voting_ends_at']);
  //        long lastCompletedPeriod = stages[i].last_completed_period;
  //
  //       if (currentDate >= endStage) {
  //          boolean resultVoting = false;
  //         double affirm = Double.parseDouble(stages[i]['affirm_stake']);
  //         double deny = Double.parseDouble(stages[i]['deny_stake']);
  //         if ( deny>=affirm ){
  //         resultVoting = false;
  //         }else  resultVoting = true;
  //         String result  = {
  //         finished: true ,
  //         affirm:resultVoting;
  //         stage: i
  //         }
  //         resultArray.add(result)
  //       }else {
  //       long startVote = Long.parseLong(stages[i]['voting_starts_at'])
  //       if(currentDate<=startVote){
  //         return ;
  //       }
  //       def result = {
  //         finished: false ,
  //         affirm:false,
  //         stage: i,
  //         periodVote:lastCompletedPeriod
  //         }
  //        resultArray.add(result);
  //       }
  //       emit(new String(resultArray))
  //     }
  //   `,
  // },
  currentVotingStage: {
    type: 'long',
    script: `
      long currentDate = (long)(new Date().getTime() * 1000000);
      long currentStage = doc['currentStage'].value;
      boolean isStagesFinished = doc['isStagesFinished'].value;
      boolean isProjectFinished = doc['isProjectFinished'].value;
      long currentVotingStage = -1;
       
      if (currentStage < -1) {
        emit(currentVotingStage);
        return;
      }
      if (currentStage === -1) {
        def stages = params['_source']['stages'];
        def lastStage = stages[stages.length - 1];
        long stageEnd = Long.parseLong(lastStage.ends_at);
        def lastStagePeriods = lastStage.periods;
        def lastPeriod = lastStagePeriods[lastStagePeriods.length - 1];
        long endPeriod = Long.parseLong(lastPeriod.ends_at);
        if (stageEnd <= currentDate && currentDate <= endPeriod) {
          currentVotingStage = stages.length - 1;
        }
        emit(currentVotingStage);
        return;
      }
      
     if (isStagesFinished && !isProjectFinished) {
        emit(currentStage);
        return;
      }
      if (currentStage >= 0) {
        currentVotingStage = currentStage - 1;
        emit(currentVotingStage);
        return;
      }
    `,
  },
  upcomingUpload: {
    type: 'long',
    script: `
      long upcomingUpload = -1;
      int currentStageId = (int)(doc['currentStage'].value);
      if (currentStageId >= 0) {
        def stageUpload = params['_source']['stages'][currentStageId]['data_upload_at'];
        stageUpload = stageUpload === null || stageUpload === '' ? '-1' : stageUpload;
        upcomingUpload = Long.parseLong(stageUpload);
      }
      emit(upcomingUpload);
    `,
  },
  timeUntilValidation: {
    type: 'long',
    script: `
      long timeUntilValidation = -1;
      long currentDate = (long)(new Date().getTime() * 1000000);
      boolean isStagesFinished = doc['isStagesFinished'].value;
      boolean isProjectFinished = doc['isProjectFinished'].value;
      int currentStageId = (int)(doc['currentStage'].value);
      
      if (isStagesFinished && !isProjectFinished) {
        def stages = params['_source']['stages'];
        def lastStage = stages[stages.length - 1];
        def validationStart = Long.parseLong(lastStage.periods[0].starts_at);
        timeUntilValidation = validationStart - currentDate;
        timeUntilValidation = timeUntilValidation >= 0 ? timeUntilValidation : -1;
        emit(timeUntilValidation);
        return;
      }
      if (currentStageId >= 0) {
        def currentStage = params['_source']['stages'][currentStageId];
        def validationStart = Long.parseLong(currentStage.periods[0].starts_at);
        timeUntilValidation = validationStart - currentDate;
        emit(timeUntilValidation);
        return;
      }
      emit(timeUntilValidation);
    `,
  },
  dateOfUpload: {
    type: 'long',
    script: `
      long dateOfUpload = -1;
      int currentVotingStageId = (int)doc['currentVotingStage'].value;
      if (currentVotingStageId >= 0) {
        def stageUpload = params['_source']['stages'][currentVotingStageId]['data_upload_at'];
        stageUpload = stageUpload === null || stageUpload === '' ? '-1' : stageUpload;
        dateOfUpload = Long.parseLong(stageUpload);
      }
      emit(dateOfUpload);
    `,
  },
  currentPeriod: {
    type: 'long',
    script: `
      long currentPeriod = -1;
      long currentDate = (long)(new Date().getTime() * 1000000);
      int currentVotingStageId = (int)doc['currentVotingStage'].value;
      if (currentVotingStageId >= 0) {
        def currentVotingStage = params['_source']['stages'][currentVotingStageId];
        def periods = currentVotingStage.periods;
        for (int i = 0; i < periods.length; i++) {
          long startPeriod = Long.parseLong(periods[i]['starts_at']);
          long endPeriod = Long.parseLong(periods[i]['ends_at']);
          if (currentDate >= startPeriod && currentDate <= endPeriod) {
            currentPeriod = (long)(i);
            break;
          }
        }
      }
      emit(currentPeriod);
    `,
  },
  mrvRang: {
    type: 'long',
    script: `
      String projectStatus = params['_source']['status'];
      if (projectStatus.equals('active') || projectStatus.equals('paused')) {
           emit(1);
      }else emit(-1);
    `,
  },
  isLastValidationBegins: {
    type: 'boolean',
    script: `
      boolean isLastValidationBegins = false;
      def stages = params['_source']['stages'];
      if (stages !== null) {
        long currentDate = (long)(new Date().getTime() * 1000000);
        def lastValidation = stages[stages.length - 1].periods[0];
        long lastValidationStart = Long.parseLong(lastValidation.starts_at);
        if (currentDate < lastValidationStart) {
          isLastValidationBegins = false;
        } else {
          isLastValidationBegins = true;
        }
      }
      emit(isLastValidationBegins);
    `,
  },
  isNewZone: {
    type: 'boolean',
    script: `
      boolean isNewZone = false;
      if(params['_source'].containsKey('subZones')) { 
      def subZones = params['_source']['subZones'];
      def leakageZone = params['_source']['leakage']['zones'];
      if(params['_source']['leakage'].containsKey('zones')){
      for (int a = 0; a < subZones.length; a++) {
      for (int count = 0; count < leakageZone.length; count++) {
         String zoneId = leakageZone[count]['id'];
         String subzoneId =  subZones[a]['subId'];
        if (zoneId != subzoneId) {
          isNewZone = true;
        } else isNewZone = false;
      }
    }
    } else isNewZone = true;
    }
      emit(isNewZone);
    `,
  },

  votingDeadline: {
    type: 'long',
    script: `
      long votingDeadline = -1;
      long currentDate = (long)(new Date().getTime() * 1000000);
      int currentVotingStageId = (int)doc['currentVotingStage'].value;
      int currentPeriodId = (int)doc['currentPeriod'].value;
      if (currentVotingStageId >= 0 && currentPeriodId >= 0) {
        def currentPeriod = params['_source']['stages'][currentVotingStageId].periods[currentPeriodId];
        long endPeriod = Long.parseLong(currentPeriod.ends_at);
        votingDeadline = endPeriod - currentDate;
        votingDeadline = votingDeadline >= 0 ? votingDeadline : -1;
      }
      emit(votingDeadline);
    `,
  },
  nextValidation: {
    type: 'long',
    script: `
      int stageId = (int)(doc['currentStage'].value);
      if(params['_source'].containsKey('startTimeProject')) {
          long nextValidation = Long.parseLong(params['_source']['stages'][stageId]['ends_at']); 
          emit(nextValidation);
        } else {
          emit(-1);
        }
    `,
  },
  startDateProject: {
    type: 'long',
    script: `
      long startDateProject =-1;
      if(params['_source'].containsKey('startTimeProject')) {
          startDateProject = Long.parseLong(params['_source']['startTimeProject']); 
        }   emit(startDateProject);
    `,
  },
  deniedDate: {
    type: 'keyword',
    script: `
      String deniedDate = '-1';
      if(params['_source']['leakage'].containsKey('dateDenied')) {
          deniedDate = params['_source']['leakage']['dateDenied']; 
        } emit(deniedDate);
    `,
  },
  submittedAtDate: {
    type: 'keyword',
    script: `
          String submittedAtDate = '-1';
        if(params['_source']['carbonApplication'].containsKey('submittedAt')) {
           submittedAtDate = params['_source']['carbonApplication']['submittedAt']; 
        } 
        emit(submittedAtDate);

    `,
  },

};

export const getMapping = (value) => value[0];

export const runtimeMappingsHistory = { amountAffirm: {
  type: 'long',
  script: `  
      double amountPrice = Double.parseDouble(params['_source']['amount']);
      if (params['_source']['affirm']) {
      long amountType=(long)(amountPrice/ 1e24); 
      emit(amountType)
      } else emit(0);`,
},
amountDeny: {
  type: 'long',
  script: ` 
      double amountPrice =Double.parseDouble(params['_source']['amount']);
      if(params['_source']['affirm']) {
       emit(0);
      } else{
        long amountType = (long)(amountPrice/ 1e24); 
        emit(amountType);}`,
} };
