import React, {
  useContext, useEffect, useState,
} from 'react';
import Button from '../../generic/Button';
import Pencil from '../../assets/Pencil';
import SettingsField from '../SettingsField';
import getConfig from '../../config';
import { appStore } from '../../state/app';
import { getContract } from '../../utils/near-utils';
import { depositValues, SettingsFieldTypes } from '../../utils/constants';
import Modal from '../Modal/Modal';
import { GAS } from '../../state/near';
import CustomSwitch from '../../generic/CustomSwitch';
import getMaintenance from '../../services/getMaintenance';
import activateMaintenanceMode from '../../services/activateMaintenanceMode';

const ProjectSettings = () => {
  const [settings, setSettings] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [current, setCurrent] = useState(null);
  const [changed, setChanged] = useState(false);
  const [saveModalShow, setSaveModalShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [backendEditMode, setBackendEditMode] = useState(false);
  const [backendBlockChanged, setBackendBlockChanged] = useState(false);
  const [maintenance, setMaintenance] = useState(null);
  const [currentMaintenance, setCurrentMaintenance] = useState(null);
  const [saveModalMaintenance, setSaveModalMaintenanceShow] = useState(false);

  const { state } = useContext(appStore);
  const { account } = state;

  const { contractMethods } = getConfig();
  const getCurrentProjectSettings = async () => {
    if (account) {
      const contract = getContract(account, contractMethods, 0);
      const currentSettings = await contract.get_current_project_settings();
      return currentSettings;
    }
  };

  const addProjectSettings = async () => {
    if (account) {
      const contract = getContract(account, contractMethods, 0);
      await contract.add_project_settings(
        {
          settings: { ...settings },
        },
        GAS,
        depositValues.one,
      );
    }
  };

  const onSubmit = async () => {
    await addProjectSettings();
    const currentSettings = await getCurrentProjectSettings();
    setSettings(null);
    setTimeout(() => {
      setSettings(currentSettings);
      setCurrent(currentSettings);
    }, 1);
    setSaveModalShow(false);
  };

  const onSaveMaintenance = async () => {
    if (maintenance) {
      await activateMaintenanceMode(maintenance);
      setSaveModalMaintenanceShow(false);
    }
  };

  useEffect(() => {
    (async () => {
      const currentSettings = await getCurrentProjectSettings();
      const maintenanceFromServer = await getMaintenance();
      setCurrentMaintenance(maintenanceFromServer);
      setMaintenance(maintenanceFromServer);
      setSettings(currentSettings);
      setCurrent(currentSettings);
    })();
  }, []);

  useEffect(() => {
    setChanged(current && settings && JSON.stringify(current) !== JSON.stringify(settings));
    if (settings) {
      const {
        project_min_duration,
        project_max_duration,
        project_min_area,
        stage_min_duration,
        initial_stage_duration,
        validation_period_lock_duration,
        validation_period_duration,
      } = settings;

      const projMinDurBiggerMin = +project_min_duration >= +project_max_duration;

      const minArea = +project_min_area < 1;

      const minStageDur = +stage_min_duration === 0;

      const minStageDurBiggerInit = +initial_stage_duration < +stage_min_duration;

      const lockPerBiggerPer = +validation_period_lock_duration > +validation_period_duration;

      const minDur = project_min_duration < 1;

      setErrors({
        ...errors,
        ...{
          project_max_duration: projMinDurBiggerMin,
          project_min_duration: projMinDurBiggerMin || minDur,
          project_min_area: minArea,
          stage_min_duration: minStageDur,
          initial_stage_duration: minStageDurBiggerInit,
          validation_period_lock_duration: lockPerBiggerPer,
          validation_period_duration: lockPerBiggerPer,
        },
      });
    }
  }, [current, settings]);

  useEffect(() => {
    setBackendBlockChanged(currentMaintenance && JSON.stringify(currentMaintenance) !== JSON.stringify(maintenance));
  }, [maintenance, currentMaintenance]);

  return (
    <div className="container">
      <div className="settings__top-block">
        <span className="settings__title">Project Settings</span>
        {changed && (
          <Button
            disabled={Object.values(errors).filter((el) => el).length > 0}
            className="settings__button-save"
            onClick={() => setSaveModalShow(true)}
          >
            <span className="settings__button-save-title">Save</span>
          </Button>
        )}
        {!changed && (
          <Button
            className={`settings__button-edit ${editMode ? 'settings__button-edit-active' : ''}`}
            onClick={() => setEditMode(!editMode)}
          >
            <Pencil active={!editMode} className="settings__pencil" />
            <span className="settings__button-edit-title">Edit</span>
          </Button>
        )}
      </div>
      {settings && (
        <div className="settings__content">
          <SettingsField
            fieldName="project_max_duration"
            label="Maximum duration of the project:"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
            errors={errors}
          />
          <SettingsField
            fieldName="project_min_duration"
            label="Minimum duration of the project:"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
            errors={errors}
          />
          <SettingsField
            fieldName="project_min_area"
            label="Minimum project area:"
            setSettings={setSettings}
            settings={settings}
            type={SettingsFieldTypes.ha}
            editMode={editMode}
            errors={errors}
          />
          <SettingsField
            fieldName="stage_min_duration"
            label="Minimum duration of the stage:"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
            errors={errors}
          />
          <SettingsField
            fieldName="initial_stages_max_number"
            label="Number of initial (first N) stages of the project:"
            setSettings={setSettings}
            settings={settings}
            type={SettingsFieldTypes.amount}
            editMode={editMode}
          />
          <SettingsField
            fieldName="initial_stage_duration"
            label="Duration of the initial stage:"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
            errors={errors}
          />
          <SettingsField
            fieldName="default_stage_duration"
            label="Duration of the default stage:"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
          />
          <SettingsField
            fieldName="voting_offset"
            label="Fixed duration until the start of voting (validation period):"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
          />
          <SettingsField
            fieldName="final_voting_offset"
            label="Fixed duration until the start of the voting of the last stage (validation period):"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
          />
          <SettingsField
            fieldName="validation_period_duration"
            label="Duration of the validation period:"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
            errors={errors}
          />
          <SettingsField
            fieldName="validation_period_lock_duration"
            label="Fixed duration until the end of the validation period during which the validator's deposit is blocked:"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
            errors={errors}
          />
          <SettingsField
            fieldName="challenge_period_duration"
            label="Duration of the challenge period:"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
          />
          <SettingsField
            fieldName="challenge_period_lock_duration"
            label="Fixed duration until the end of the challenge period during which the validator's deposit is blocked:"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
          />
          <SettingsField
            fieldName="challenge_period_stake_multiplier"
            label="Multiplier for calculating the required deposit for the challenge period:"
            setSettings={setSettings}
            settings={settings}
            type={SettingsFieldTypes.amount}
            editMode={editMode}
          />

          <SettingsField
            fieldName="escalation_period_max_number"
            label="Maximum number of escalation periods:"
            setSettings={setSettings}
            settings={settings}
            type={SettingsFieldTypes.amount}
            editMode={editMode}
          />
          <SettingsField
            fieldName="resolution_period_duration"
            label="Duration of the resolution period:"
            setSettings={setSettings}
            settings={settings}
            editMode={editMode}
          />
        </div>
      )}
      {maintenance && (
        <div className="settings__maintenance">
          <div className="settings__maintenance-field">
            <span className="settings__maintenance-title">Maintenance page in the Dashboard</span>
            <CustomSwitch
              disabled={!backendEditMode}
              checked={maintenance?.dashboard}
              onChange={() => setMaintenance({
                ...maintenance,
                dashboard: !maintenance?.dashboard,
              })}
            />
          </div>
          {backendBlockChanged && (
            <Button className="settings__button-save" onClick={() => setSaveModalMaintenanceShow(true)}>
              <span className="settings__button-save-title">Save</span>
            </Button>
          )}
          {!backendBlockChanged && (
            <Button
              className={`settings__button-edit ${backendEditMode ? 'settings__button-edit-active' : ''}`}
              onClick={() => setBackendEditMode(!backendEditMode)}
            >
              <Pencil active={!backendEditMode} className="settings__pencil" />
              <span className="settings__button-edit-title">Edit</span>
            </Button>
          )}
        </div>
      )}
      <Modal show={saveModalShow || saveModalMaintenance}>
        <div className="settings__modal-content">
          <span className="settings__modal-text">Are you sure you want to save changes?</span>
          <div className="settings__modal">
            <Button
              className="settings__modal-no"
              onClick={() => {
                setSaveModalShow(false);
                setSaveModalMaintenanceShow(false);
              }}
            >
              <span className="settings__button-save-title">No</span>
            </Button>
            <Button className="settings__modal-yes" onClick={() => (saveModalShow ? onSubmit() : onSaveMaintenance())}>
              <span className="settings__button-save-title">Yes</span>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectSettings;
