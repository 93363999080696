import React from 'react';
import ReactTooltip from 'react-tooltip';

const CustomTooltip = ({ id, message, customClass = 'tooltip' }) => (
  <ReactTooltip
    key={id}
    id={id}
    effect="solid"
    clickable
    delayHide={300}
    className={customClass}
    place="top-start"
    offset={{ top: -8, left: 5 }}
    border
    textColor="#58667e"
    backgroundColor="#fff"
    borderColor="#329D9C"
    tooltipRadius="8"
    isCapture
  >
    <span className="tooltip__text">{message}</span>
  </ReactTooltip>
);

export default CustomTooltip;
