import React, {
  useEffect, useState, useRef,
} from 'react';
import bbox from '@turf/bbox';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Loader from '../../Loader';
import { customTheme } from '../mapTheme';

mapboxgl.workerClass = MapboxWorker;
const MapMini = ({ state, withGeoCoder = false, drawControls = {} }) => {
  const modes = MapboxDraw.modes;
  modes.static = StaticMode;
  const [tools, setTools] = useState();
  const mapRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const initializeMap = () => {
    try {
      mapboxgl.accessToken = process.env.REACT_APP_MAP_KEY;
      const mapInstance = new mapboxgl.Map({
        container: mapRef.current, // container id
        style: 'mapbox://styles/mapbox/satellite-streets-v11', // hosted style id
        center: [0, 0], // starting position
        zoom: 0,
        preserveDrawingBuffer: true,
        boxZoom: false,
        dragRotate: false,
        keyboard: false,
        touchPitch: false,
        touchZoomRotate: false,
      });
      const drawInstance = new MapboxDraw({
        displayControlsDefault: false,
        controls: drawControls,
        userProperties: true,
        modes,
        styles: customTheme,
        keybindings: false,
        boxSelect: false,
      });
      const geocoderInstance = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        language: 'en-US',
        marker: false,
        placeholder: 'Search...',
      });
      const navInstance = new mapboxgl.NavigationControl({
        showCompass: false,
      });

      mapInstance.addControl(drawInstance);
      mapInstance.addControl(navInstance);
      if (withGeoCoder) {
        mapInstance.addControl(geocoderInstance, 'top-left');
      }
      mapInstance.resize();
      setTools({ map: mapInstance, draw: drawInstance });
    } catch (e) {
      console.log(e);
    }
  };

  const addMapEventListeners = () => {
    tools.map.scrollZoom.disable();
    tools.draw.changeMode('static');
  };
  const moveMap = (polygon) => {
    const box = bbox(polygon);
    tools.map.fitBounds(box, { animate: false }, { padding: { top: 30, bottom: 30, left: 30, right: 30 } });
  };

  const drawInitialData = () => {
    // If nft zone exist - add it to the map
    const redrawAllFeatures = (data) => {
      new Promise((resolve) => {
        tools.draw.deleteAll();
        resolve(true);
      }).then(() => {
        if (data?.polygon) {
          tools.draw.add(data.polygon[0]);
        }
        if (data?.subZones) {
          data?.subZones.forEach((item) => {
            tools.draw.add(item.polygon[0]);
          });
        }
        if (data?.sampleZones) {
          data?.sampleZones.forEach((item) => tools.draw.add(item));
        }
      });
    };
    if (state?.polygon?.length) {
      redrawAllFeatures(state);
      moveMap(state.polygon[0]);
      return;
    }
    tools.map.flyTo({ center: [5, 34], zoom: 2 });
  };

  useEffect(() => {
    if (!tools) return;
    try {
      addMapEventListeners();
      drawInitialData();
    } catch (e) {
      console.log(e);
    }
  }, [tools]);

  useEffect(() => {
    setIsLoading(true);
    initializeMap();
    setIsLoading(false);
  }, [state]);

  return (
    <div className="map__mini">
      {isLoading && <div className="cropper__loader"><Loader /></div>}
      <div className="map__box">
        <div ref={mapRef} className="map__map" />
      </div>
    </div>
  );
};

export default MapMini;
