import React from 'react';
import Switch from 'react-switch';

const CustomSwitch = ({ register, onChange, value }) => (
  <Switch
    checked={!!value}
    onChange={onChange}
    handleDiameter={16}
    offColor="#DADEE3"
    onColor="#DADEE3"
    offHandleColor="#fff"
    onHandleColor="#fff"
    height={22}
    width={48}
    borderRadius={6}
    activeBoxShadow="0px 0px 1px 2px #4d4d4c"
    uncheckedIcon={<div className="custom-switch__names">OFF</div>}
    checkedIcon={<div className="custom-switch__names">ON</div>}
    className="react-switch"
    id="small-radius-switch"
    {...register}
  />
);

export default CustomSwitch;
