import React from 'react';

const ApproveCarbon = ({ active }) => {
  const fillColor = active ? '#329D9C' : '#CECECE';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H19C21.7614 0 24 2.23858 24 5V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V5Z"
        fill={fillColor}
      />
      <path d="M4.3277 11.85C4.3277 11.232 4.4657 10.68 4.7417 10.194C5.0237 9.702 5.4047 9.321 5.8847 9.051C6.3707 8.775 6.9137 8.637 7.5137 8.637C8.2157 8.637 8.8307 8.817 9.3587 9.177C9.8867 9.537 10.2557 10.035 10.4657 10.671H9.0167C8.8727 10.371 8.6687 10.146 8.4047 9.996C8.1467 9.846 7.8467 9.771 7.5047 9.771C7.1387 9.771 6.8117 9.858 6.5237 10.032C6.2417 10.2 6.0197 10.44 5.8577 10.752C5.7017 11.064 5.6237 11.43 5.6237 11.85C5.6237 12.264 5.7017 12.63 5.8577 12.948C6.0197 13.26 6.2417 13.503 6.5237 13.677C6.8117 13.845 7.1387 13.929 7.5047 13.929C7.8467 13.929 8.1467 13.854 8.4047 13.704C8.6687 13.548 8.8727 13.32 9.0167 13.02H10.4657C10.2557 13.662 9.8867 14.163 9.3587 14.523C8.8367 14.877 8.2217 15.054 7.5137 15.054C6.9137 15.054 6.3707 14.919 5.8847 14.649C5.4047 14.373 5.0237 13.992 4.7417 13.506C4.4657 13.02 4.3277 12.468 4.3277 11.85ZM14.4579 15.063C13.8699 15.063 13.3299 14.925 12.8379 14.649C12.3459 14.373 11.9559 13.992 11.6679 13.506C11.3799 13.014 11.2359 12.459 11.2359 11.841C11.2359 11.229 11.3799 10.68 11.6679 10.194C11.9559 9.702 12.3459 9.318 12.8379 9.042C13.3299 8.766 13.8699 8.628 14.4579 8.628C15.0519 8.628 15.5919 8.766 16.0779 9.042C16.5699 9.318 16.9569 9.702 17.2389 10.194C17.5269 10.68 17.6709 11.229 17.6709 11.841C17.6709 12.459 17.5269 13.014 17.2389 13.506C16.9569 13.992 16.5699 14.373 16.0779 14.649C15.5859 14.925 15.0459 15.063 14.4579 15.063ZM14.4579 13.938C14.8359 13.938 15.1689 13.854 15.4569 13.686C15.7449 13.512 15.9699 13.266 16.1319 12.948C16.2939 12.63 16.3749 12.261 16.3749 11.841C16.3749 11.421 16.2939 11.055 16.1319 10.743C15.9699 10.425 15.7449 10.182 15.4569 10.014C15.1689 9.846 14.8359 9.762 14.4579 9.762C14.0799 9.762 13.7439 9.846 13.4499 10.014C13.1619 10.182 12.9369 10.425 12.7749 10.743C12.6129 11.055 12.5319 11.421 12.5319 11.841C12.5319 12.261 12.6129 12.63 12.7749 12.948C12.9369 13.266 13.1619 13.512 13.4499 13.686C13.7439 13.854 14.0799 13.938 14.4579 13.938Z" fill="white" />
      <path d="M17.7396 16.368C17.9956 16.1547 18.1996 15.9773 18.3516 15.836C18.5036 15.692 18.6302 15.5427 18.7316 15.388C18.8329 15.2333 18.8836 15.0813 18.8836 14.932C18.8836 14.796 18.8516 14.6893 18.7876 14.612C18.7236 14.5347 18.6249 14.496 18.4916 14.496C18.3582 14.496 18.2556 14.5413 18.1836 14.632C18.1116 14.72 18.0742 14.8413 18.0716 14.996H17.5276C17.5382 14.676 17.6329 14.4333 17.8116 14.268C17.9929 14.1027 18.2222 14.02 18.4996 14.02C18.8036 14.02 19.0369 14.1013 19.1996 14.264C19.3622 14.424 19.4436 14.636 19.4436 14.9C19.4436 15.108 19.3876 15.3067 19.2756 15.496C19.1636 15.6853 19.0356 15.8507 18.8916 15.992C18.7476 16.1307 18.5596 16.2987 18.3276 16.496H19.5076V16.96H17.5316V16.544L17.7396 16.368Z" fill="white" />
    </svg>

  );
};

export default ApproveCarbon;
