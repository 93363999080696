import React from 'react';
import Button from '../../generic/Button';

const Modal = ({ show, close, title, description, closeText, children, wide }) => (
  show && (
  <div className="modal">
    <div className="modal__bg" />
    <div className={`modal__wrapper ${wide ? 'modal__wide' : ''}`}>
      {children}
      {!children && (
      <>
        <div className="modal__desc">
          <h2>{title}</h2>
          <p className="first">{description}</p>
        </div>
        <div className="modal__btn">
          <Button onClick={close}>
            <span>{closeText}</span>
          </Button>
        </div>
      </>
      )}
    </div>
  </div>
  )
);

export default Modal;
