import React from 'react';

const CustomInput = ({
  label,
  value,
  onChange,
  type,
  name,
  register,
  error,
  placeholder,
  customClassName = '',
  onlyNumber,
  disabled,
}) => {
  const onNumberOnlyChange = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp('[0-9]').test(keyValue);
    if (!isValid) {
      event.preventDefault();
    }
  };
  return (
    <div className={`input-field ${customClassName}`}>
      {label && <label className="input-field_label">{label}</label>}
      <div className={`input-field_input ${error ? 'input-field-error' : ''} ${error && type === 'checkbox' ? 'checkbox-error' : ''
      }`}
      >
        {register ? (
          <input
            type={type}
            value={value}
            {...register()}
            onChange={onChange}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            onKeyPress={onlyNumber ? onNumberOnlyChange : () => {}}
          />
        ) : (
          <input
            type={type}
            value={value}
            onChange={onChange}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            onKeyPress={onlyNumber ? onNumberOnlyChange : () => {}}
          />
        )}
        {error && <span className="input-field-error-message">{error.message ? error.message : 'this field is required.'}</span>}
      </div>
    </div>
  );
};

export default CustomInput;
