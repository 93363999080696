import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory, useParams } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import HeaderSteps from '../Evaluate/HeaderSteps';
import Button from '../../generic/Button';
import closeIcon from '../../assets/close.svg';
import CopyEmail from '../../components/CopyEmail';
import CustomCheckbox from '../../generic/CustomCheckbox';
import CustomInput from '../../generic/CustomInput';
import CustomSelect from '../../generic/CustomSelect';
import Footer from '../../layouts/Footer';
import CustomPopup from '../../generic/CustomPopup';
import BaselineList from './BaselineList';
import { notMoreHundred } from '../../utils/helpers';
import { initIPFS } from '../../state/ipfs';
import useLeakage from '../../hooks/useLeakage';
import Loader from '../../components/Loader';
import getInfoProject from '../../services/getInfoProject';
import { carbonParams } from '../../constans/constans';
import { nanoToMicro } from '../../helpers/time-convert';

const Leakage = () => {
  const { zonesArray,
    projectData,
    isLoading,
    setZonesArray, callUpdateMethod, showError, setShowError } = useLeakage();
  const { id } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [approveType, setApproveType] = useState({ text: 'Denying', approve: false });
  const [info, setInfo] = useState('');
  const [comment, setComment] = useState({ text: '', notNull: false });
  const [newPath, setNewPath] = useState('');
  const [defaultState, setDefaultState] = useState({ leakage: '', forestType: '', rainfall: '' });
  const history = useHistory();
  const { formState: { errors }, register, handleSubmit, clearErrors, control, setValue } = useForm();

  const onApprove = async (data) => {
    const dataWithZones = { ...data, zones: zonesArray };
    const updatedData = { ...projectData, leakage: { ...dataWithZones, carbonApprove: true } };
    const ipfs = await initIPFS();
    const { path } = await ipfs.add(JSON.stringify(updatedData));
    setNewPath(path);
    setShowPopup(true);
    setApproveType({ text: 'Approving', approve: true });
  };

  const getEmailOrganisation = async () => {
    try {
      const res = await getInfoProject(id);
      setInfo({ email: res?.organization.email, accountId: res.near_account_id, ProjectOperatorId: res?.id });
    } catch {
      setInfo('');
    }
  };
  useEffect(() => {
    const getInfoAboutProject = async () => {
      await getEmailOrganisation();
    };
    getInfoAboutProject();
  }, []);

  const onDeny = async () => {
    if (comment.text) {
      const updatedData = { ...projectData, leakage: { comment: comment.text, dateDenied: JSON.stringify(nanoToMicro(Date.now())), carbonApprove: false } };
      const ipfs = await initIPFS();
      const { path } = await ipfs.add(JSON.stringify(updatedData));
      setComment({ ...comment, notNull: false });
      setApproveType({ text: 'Denying', approve: false });
      setShowPopup(true);
      return setNewPath(path);
    }
    setComment({ ...comment, notNull: true });
  };
  const validLeakage = (val) => {
    const result = notMoreHundred(val);
    setDefaultState({ ...defaultState, leakage: result });
  };
  if (isLoading) {
    return (
      <div className="loader__wrapper">
        <Loader />
      </div>
    );
  }
  if (projectData?.leakage) {
    return (
      <CustomPopup widthParam="wrapper-popup" setShowPopUp={() => history.push('/')}>
        <div className="leakage__popup">
          <p className="leakage__popup-desc">
            This project already have leakage data
          </p>

          <Button
            onClick={() => {
              history.push('/');
            }}
          >
            <span>Ok</span>
          </Button>
        </div>
      </CustomPopup>

    );
  }
  return (
    <div className="leakage">
      <HeaderSteps />
      <div className="leakage__wrapper">
        <div className="leakage__title">
          <span>{projectData?.name}</span>
          <div className="leakage__wrapper-btn">
            <Button
              className="leakage__btn"
              onClick={() => {
                history.push('/');
              }}
            >
              <ReactSVG src={closeIcon} />
              <span>Cancel</span>
            </Button>
          </div>
        </div>
        <div className="leakage__block-wrapper">
          <form className="leakage__form" onSubmit={handleSubmit(onApprove)}>
            <div className="leakage__block">
              <div className="leakage__form-block">
                <span>Comment (if project denied)</span>
                {info?.email && <CopyEmail email={info?.email} />}
              </div>
              <textarea
                className={`textarea leakage__textarea ${Object.keys(errors).includes('comment') || comment.notNull ? 'error' : ''}`}
                name="comment"
                placeholder="Type here"
                {...register('comment')}
                onChange={(e) => {
                  setComment({ text: e.target.value, notNull: false });
                  clearErrors('comment');
                }}
              />
              {(Object.keys(errors).includes('comment') || comment.notNull) && (
              <span className="input-field-error-message">
                this field is required
                {' '}
              </span>
              )}
            </div>
            <div className="leakage__block">
              <div className="leakage__form-block">
                <span>Leakage (%)</span>
              </div>
              <div className="leakage__block-w-check">
                <CustomInput
                  placeholder="123"
                  onChange={(e) => {
                    validLeakage(e.target.value);
                    clearErrors('leakage');
                  }}
                  register={() => register('leakage', { required: true, min: 0, max: 100 })}
                  customClassName="leakage__input pre-full"
                  name="leakage"
                  value={defaultState.leakage}
                  onlyNumber
                  error={errors?.leakage}
                />
                <CustomCheckbox
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      clearErrors('leakage');
                      setValue('leakage', '5');
                      return validLeakage('5');
                    }
                  }}
                  value="5"
                  title="Use default value 5%"
                  description=""
                  key="yes"
                  customClass="leakage__checkbox"
                />
              </div>
            </div>
            {zonesArray.length > 0 && (
            <BaselineList
              zones={zonesArray}
              projectData={projectData}
              register={register}
              setZones={setZonesArray}
              defaultState={defaultState}
              setDefaultState={setDefaultState}
              errors={errors}
              clearErrors={clearErrors}
              setValue={setValue}
            />
            )}
            <div className="leakage__block">
              <div className="leakage__form-block">
                <span>Carbon parameters </span>
              </div>
              <div className="leakage__block">
                <Controller
                  control={control}
                  defaultValue={defaultState.forestType}
                  name="forestType"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, name } }) => (
                    <CustomSelect
                      placeholder="Сhoose the forest type"
                      change={(e) => {
                        clearErrors('forestType');
                        setDefaultState({ ...defaultState, forestType: e.value });
                        onChange(e.type);
                      }}
                      customClassName="leakage__carbons-select mb "
                      optionArray={carbonParams}
                      withCheck
                      name={name}
                      ref={null}
                      classSelect="leakage__carbons-select-field "
                      label="Forest type"
                      value={defaultState.forestType}
                      error={errors.forestType}
                    />
                  )}
                />
                <CustomInput
                  placeholder="123"
                  label="Annual rainfall (mm/y)"
                  onChange={(e) => {
                    setDefaultState({ ...defaultState, rainfall: e.target.value });
                    clearErrors('rainfall');
                  }}
                  onlyNumber
                  name="rainfall"
                  value={defaultState.rainfall}
                  register={() => register('rainfall', { required: true })}
                  customClassName="leakage__carbon-input rainfall "
                  error={errors.rainfall}
                />
              </div>
              <div className="leakage__wrapper-btns">
                <Button className="cancel" onClick={history.goBack}>Back</Button>
                <Button className="deny" onClick={() => onDeny()}>Deny</Button>
                <Button type>Approve</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
      <CustomPopup widthParam="wrapper-popup" setShowPopUp={() => setShowPopup(false)}>
        <div className="leakage__popup">
          <p className="leakage__popup-desc">
            You’re
            <span>
              {' '}
              {approveType.text}
              {' '}

            </span>
            this project. This action will send an email to the project operator with the decision.
          </p>
          <div className="leakage__popup-btns">
            <Button
              onClick={() => setShowPopup(false)}
              className="cancel"
            >
              <span>Cancel</span>
            </Button>
            <Button
              onClick={async () => {
                setShowPopup(false);
                await callUpdateMethod(newPath, approveType.approve, info, comment?.text);
              }}
            >
              <span>Continue</span>
            </Button>
          </div>
        </div>
      </CustomPopup>
      )}
      {showError && (
      <CustomPopup
        widthParam="wrapper-popup"
        setShowPopUp={() => {
          history.push('/');
          setShowError(false);
        }}
      >
        <div className="leakage__popup">
          <p className="leakage__popup-title">
            Oops!
          </p>
          <p className="leakage__popup-desc">
            Something goes wrong!
          </p>
          <Button
            className="leakage__popup-btn"
            onClick={() => {
              setShowError(false);
              history.push('/');
            }}
          >
            <span>Ok</span>
          </Button>
        </div>
      </CustomPopup>
      )}
      <Footer customClass="absolute-center" />
    </div>
  );
};

export default Leakage;
