/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../../assets/ArrowUp.svg";
import { contractMethods, GAS } from "../../state/near";
import {checkTransaction, getContract, getHexTxFromUrl, getTxHashes, methodsNames} from '../../utils/near-utils';
import Button from "../../generic/Button";
import { appStore } from "../../state/app";
import { useLocation } from "react-router";
import { storageKeys} from '../../utils/constants';
import {checkVerifiedStatus, concatItems, decodeBase64} from '../../helpers/main';
import CustomPopup from "../../generic/CustomPopup";

const ListItem = ({
  contact_person,
  countries,
  days_in_project,
  description,
  website,
  email,
  short_description,
  types,
  name,
  ap_or_re_comment,
  id,
  verified,
  projectVerifing,
  near_account_id,
  verifiedStatus,
  verifiedDate,
    isLast,
                    itemRef
}) => {
  const [toggle, setToggle] = useState(false);
  const [comment, setComment] = useState(JSON.parse(localStorage.getItem(storageKeys.comment+name)) ?? '');
  const [wasVerifiedLabel, setWasVerifiedLabel] = useState('');
  const [paramsPopup, setParamsPopup] = useState({text:'approve', approve:false ,show:false});
  const {state} = useContext(appStore);
  const {account} = state;
  const {pathname} = useLocation();
  const nearAccountId = near_account_id;
  const txHashes = getTxHashes();
  const currentHash = txHashes && getHexTxFromUrl(txHashes);
  const ref = isLast ? { ref: itemRef } : {};

  const reloadComment = localStorage.getItem(storageKeys.comment+name);

  const getContractId = async () => {
    const contract = getContract(account, contractMethods, 0);
    localStorage.setItem(storageKeys.currentNearId, nearAccountId);
    const valueDeposit = await contract.storage_balance_bounds()
    await contract.storage_deposit(
      {
        account_id: nearAccountId,
        registration_only: false,
      },
      GAS,
      valueDeposit.min,
    );
  };

  const onReject = () => {
    projectVerifing(false, id, comment);
    localStorage.removeItem(storageKeys.comment+name);
  };

  const checkSignedTransaction = (hash, pathname) => {
    checkTransaction(hash, nearAccountId).then(data => {
      try {
        const transaction = data.transaction;
        const args = transaction?.actions[0]?.FunctionCall?.args;
        const methodName = transaction?.actions[0]?.FunctionCall?.method_name;
        const currentNearIdApproved = localStorage.getItem(storageKeys.currentNearId);

        const acc = JSON.parse(decodeBase64(args)).account_id;
        if (acc === nearAccountId) {
          projectVerifing(true, id, reloadComment);
          localStorage.removeItem(storageKeys.comment+name);
          localStorage.removeItem(storageKeys.currentNearId);
        } else if (nearAccountId === currentNearIdApproved && methodName === methodsNames.confirm) {
          projectVerifing(true, id, reloadComment);
          localStorage.removeItem(storageKeys.comment+name);
          localStorage.removeItem(storageKeys.currentNearId);
        } else {
          return;
        }
      } catch (error) {
        return null;
      }
      window.history.replaceState({}, '', window.location.origin + pathname);
    });
  };

  useEffect(() => {
    if (currentHash) {
      return checkSignedTransaction(currentHash, pathname);
    }
    localStorage.removeItem(storageKeys.currentNearId);
    if (verifiedStatus) {
      checkVerifiedStatus(verifiedStatus, setWasVerifiedLabel);
    }
  }, []);

  useEffect(() => {
    if(comment) return localStorage.setItem(storageKeys.comment + name , JSON.stringify(comment));
    return localStorage.removeItem(storageKeys.comment + name);
  }, [comment]);

  const onAccept = () => {
    if(paramsPopup.approve){
      return getContractId();
    }
    return onReject();
  }

  return (
    <div className="item" {...ref}>
      <div className="item-header" onClick={() => setToggle(!toggle)}>
        <div className="item-title">
          <span className="item-field_name">Organization name:</span>
          {name}
        </div>
        <div className="item-img">{toggle ? <ArrowUp /> : <ArrowDown />}</div>
      </div>
      <div className={`item-content ${toggle ? '' : 'hide'}`}>
        <span className="item-field">
          <span className="item-field_name">Contact person:</span>
          {contact_person}
        </span>
        <span className="item-field">
          <span className="item-field_name">NEAR account ID:</span>
          {near_account_id}
        </span>
        <span className="item-field">
          <span className="item-field_name">Email:</span>
          {email}
        </span>
        <span className="item-field">
          <span className="item-field_name">Description of organization:</span>
          {description}
        </span>
        <span className="item-field">
          <span className="item-field_name">Website:</span>
          {website}
        </span>
        <span className="item-field">
          <span className="item-field_name">Short description of the forest project(s):</span>
          {short_description}
        </span>
        <span className="item-field">
          <span className="item-field_name">Countries in which the organization is active:</span>
          {concatItems(countries)}
        </span>
        <span className="item-field">
          <span className="item-field_name">Type of project:</span>
          {concatItems(types)}
        </span>
        <span className="item-field">
          <span className="item-field_name">How long have you been in operation/developing forest projects?:</span>
          {days_in_project}
        </span>
        {wasVerifiedLabel && (
          <span className="item-field">
            <span className="item-field_name">{wasVerifiedLabel}</span>
            {`${new Date(verifiedDate).toLocaleString()}`}
          </span>
        )}

        {!verified && (
          <div className="item-comment">
            <span className="item-field_name">Comment</span>
            <div className="item-textarea">
              <span>{ap_or_re_comment || '-'}</span>
            </div>
          </div>
        )}

        {verified && (
          <>
            <div className="btn-box">
              <Button
                disabled={!comment}
                className="item-approve"
                type="submit"
                onClick={() => setParamsPopup({
                  text:'approve',
                  approve:true,
                  show: true,
                })}>
                Approve
              </Button>
              <Button
                disabled={!comment}
                className="item-reject"
                type="button"
                onClick={() => setParamsPopup({
                  text:'reject',
                  approve:false,
                  show: true,
                })}>
                Reject
              </Button>
            </div>
            <div className='item-important'><span>Important:</span> Please enter comment </div>
            <textarea
              className="textarea"
              name="comment"
              placeholder="Type here"
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
          </>
        )}
      </div>
      {paramsPopup.show && <CustomPopup widthParam="wrapper-popup" setShowPopUp={() => setParamsPopup({
        ...paramsPopup,
        show: false
      })}>
        <div className="item__popup">
          <p className="item__popup-desc">
            Are you sure you want to
            {' '}
            {paramsPopup.text}
            <span>
              {' '}
              {name}?
            </span>
          </p>
          <div className="item__popup-btns">
            <Button
              onClick={() => setParamsPopup({
                ...paramsPopup,
                show: false
              })}
              className="cancel"
            >
              <span>No</span>
            </Button>
            <Button
              onClick={async () => {
                setParamsPopup({
                  ...paramsPopup,
                  show: false
                });
                onAccept();
              }}
            >
              <span>Yes</span>
            </Button>
          </div>
        </div>
      </CustomPopup>
      }
    </div>
  );
};

export default ListItem;
