import React, { useEffect, useState } from 'react';
import { convertMillisecondsToDHM, convertToMilliseconds } from '../../helpers/time-convert';
import { SettingsFieldTypes, ValidationConstants } from '../../utils/constants';

const SettingsField = ({ label, settings, setSettings, fieldName, type = null, editMode, errors }) => {
  const [value, setValue] = useState(!type ? { days: '', hours: '', minutes: '' } : '');

  useEffect(() => {
    let initialValue;
    if (!type) {
      const millisecondsValue = +settings[fieldName] / 1e6;
      initialValue = convertMillisecondsToDHM(millisecondsValue);
    } else if (type === SettingsFieldTypes.ha) {
      initialValue = +settings[fieldName] / 10000;
    } else if (settings[fieldName]) {
      initialValue = +settings[fieldName];
    }
    setValue(initialValue);
  }, []);

  useEffect(() => {
    if (value && !type) {
      const { days, hours, minutes } = value;
      setSettings({
        ...settings,
        [fieldName]: `${convertToMilliseconds(days, hours, minutes) * 1e6}`,
      });
    } else if (type === SettingsFieldTypes.ha) {
      setSettings({
        ...settings,
        [fieldName]: `${value * 10000}`,
      });
    } else if (value) {
      setSettings({
        ...settings,
        [fieldName]: value,
      });
    }
  }, [value]);

  const renderField = () => {
    if (type === SettingsFieldTypes.ha || type === SettingsFieldTypes.amount) {
      return (
        <div className="sfield__input-wrapper">
          <input
            disabled={!editMode}
            className={`sfield__input ${errors?.[fieldName] ? 'sfield__input-error' : ''}`}
            value={value}
            onChange={(e) => {
              const target = e.target.value;
              if (type === SettingsFieldTypes.ha) {
                if (
                  !Number.isNaN(parseFloat(+target))
                  && Number.isFinite(+target)
                  && !(target[0] === '0' && target[1] !== '.' && target.length > 1)
                ) {
                  setValue(target);
                }
              } else if (`${target}`.length < 8 && ValidationConstants.SETTINGS_FIELD.test(target)) {
                setValue(+target);
              }
            }}
          />
          {type === SettingsFieldTypes.ha && <span className="sfield__value-name">Ha</span>}
        </div>
      );
    }

    if (!type) {
      return (
        <>
          <div className="sfield__input-wrapper">
            <input
              disabled={!editMode}
              className={`sfield__input ${errors?.[fieldName] ? 'sfield__input-error' : ''}`}
              value={value?.days}
              onChange={(e) => {
                const target = e.target.value;
                if (ValidationConstants.SETTINGS_FIELD.test(target) && `${target}`.length < 8) {
                  setValue({
                    ...value,
                    days: +target,
                  });
                }
              }}
            />
            <span className="sfield__value-name">Days</span>
          </div>
          <div className="sfield__input-wrapper">
            <input
              disabled={!editMode}
              className={`sfield__input ${errors?.[fieldName] ? 'sfield__input-error' : ''}`}
              value={value?.hours}
              onChange={(e) => {
                const target = e.target.value;
                if (ValidationConstants.SETTINGS_FIELD.test(target) && target <= 24) {
                  setValue({
                    ...value,
                    hours: +target,
                  });
                }
              }}
            />
            <span className="sfield__value-name">Hours</span>
          </div>
          <div className="sfield__input-wrapper">
            <input
              disabled={!editMode}
              className={`sfield__input ${errors?.[fieldName] ? 'sfield__input-error' : ''}`}
              value={value?.minutes}
              onChange={(e) => {
                const target = e.target.value;
                if (ValidationConstants.SETTINGS_FIELD.test(target) && target <= 60) {
                  setValue({
                    ...value,
                    minutes: +target,
                  });
                }
              }}
            />
            <span className="sfield__value-name">Minutes</span>
          </div>
        </>
      );
    }
  };
  return (
    <div className="sfield">
      <span className="sfield__label">{label}</span>
      {renderField()}
    </div>
  );
};

export default SettingsField;
