import React from 'react';

const TableColumnNames = ({ names }) => (
  <div className="table-column-names">
    {names?.map((el) => (
      <div key={el.name} style={{ width: el.width }}>
        <div className="table-column-names__item">{el.name}</div>
      </div>
    ))}
  </div>
);

export default TableColumnNames;
