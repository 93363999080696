import React from 'react';
import { ReactComponent as SortIcon } from '../../assets/sorting-arrow.svg';

const Sorting = ({ viewType, activeSort, setActiveSort }) => {
  const newCarbonSortingItems = [
    { label: 'Name', key: 'name.keyword' },
    { label: 'Organisation', key: 'developer.keyword' },
    { label: 'Size', key: 'parsedSquare' },
    { label: 'Appl.date', key: 'submittedAtDate' },
    { label: 'MRV', key: 'mrvRang' },
  ];
  const approvedCarbonItems = [
    { label: 'Name', key: 'name.keyword' },
    { label: 'Organisation', key: 'developer.keyword' },
    { label: 'Size', key: 'parsedSquare' },
    { label: 'Next Validation', key: 'nextValidation' },
    { label: 'MRV', key: 'mrvRang' },
  ];
  const deniedCarbonItems = [
    { label: 'Name', key: 'name.keyword' },
    { label: 'Organisation', key: 'developer.keyword' },
    { label: 'Size', key: 'parsedSquare' },
    { label: 'Appl.date', key: 'deniedDate' },
    { label: 'MRV', key: 'mrvRang' },
  ];

  const handleSort = (key) => {
    if (!key) return;

    if (activeSort.key === key) {
      setActiveSort({ ...activeSort, value: activeSort.value === 'asc' ? 'desc' : 'asc' });
      return;
    }
    setActiveSort({ key, value: 'asc' });
  };

  if (viewType === 'newPage') {
    return (
      <div className="sorting sorting__new">
        {newCarbonSortingItems.map((item) => (
          <div className="sorting__item" onClick={() => handleSort(item.key)} key={item.key + item.label}>
            <div className="sorting__text">{item.label}</div>
            {item.key && (
            <div className={`sorting__wrapper-icon ${(activeSort.key === item.key && activeSort.value === 'asc') ? 'active' : ''}`}>
              <SortIcon />
            </div>
            )}
          </div>
        ))}
      </div>
    );
  }

  if (viewType === 'approvePage') {
    return (
      <div className="sorting sorting__approve">
        {approvedCarbonItems.map((item) => (
          <div className="sorting__item" onClick={() => handleSort(item.key)} key={item.key + item.label}>
            <div className="sorting__text">{item.label}</div>
            {item.key && (
            <div className={`sorting__wrapper-icon ${(activeSort.key === item.key && activeSort.value === 'asc') ? 'active' : ''}`}>
              <SortIcon />
            </div>
            )}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="sorting sorting__denied">
      {deniedCarbonItems.map((item) => (
        <div className="sorting__item" onClick={() => handleSort(item.key)} key={item.key + item.label}>
          <div className="sorting__text">{item.label}</div>
          {item.key && (
            <div className={`sorting__wrapper-icon ${(activeSort.key === item.key && activeSort.value === 'asc') ? 'active' : ''}`}>
              <SortIcon />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Sorting;
