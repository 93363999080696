import React from 'react';

const Pencil = ({ active }) => {
  const fillColor = active ? '#329D9C' : '#fff';
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.68333 16.1495C1.44956 16.1491 1.22672 16.0506 1.06916 15.8779C0.908699 15.7066 0.828965 15.475 0.849995 15.2412L1.05416 12.9962L10.4858 3.56787L13.4333 6.51454L4.00416 15.942L1.75916 16.1462C1.73333 16.1487 1.70749 16.1495 1.68333 16.1495ZM14.0217 5.92537L11.075 2.9787L12.8425 1.2112C12.9988 1.05472 13.2109 0.966797 13.4321 0.966797C13.6533 0.966797 13.8654 1.05472 14.0217 1.2112L15.7892 2.9787C15.9456 3.13501 16.0336 3.34711 16.0336 3.56829C16.0336 3.78946 15.9456 4.00156 15.7892 4.15787L14.0225 5.92454L14.0217 5.92537Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default Pencil;
