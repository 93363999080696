import { storageKeys } from '../utils/constants';
import { StateUtils } from '../utils/state-utils';
import { initNear } from './near';

const initialState = {
  app: {
    theme: localStorage.getItem(storageKeys.theme) ? localStorage.getItem(storageKeys.theme) : 'light',
    projects: [],
    approved_projects: [],
    rejected_projects: [],
    loading: false,
    counterZones: 0,
  },
  near: {
    initialized: false,
  },
};

export const { appStore, AppProvider } = StateUtils(initialState, 'app');

export const onAppMount = () => async ({ dispatch }) => {
  document.documentElement.classList.add(`theme-${initialState.app.theme}`);
  dispatch(await initNear());
};
