import {
  useState, useEffect, useMemo,
} from 'react';

import {
  handleResponseStatus,
  sendRequest,
  newCarbonBodyRequest,
  deniedCarbonBodyRequest,
  approveCarbonBodyRequest,
} from '../utils/api-request';
import getConfig from '../config';

const { indexerApi } = getConfig();

const useProjectsList = (filterQuery, activeSort, typeOfCarbon) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [projectsList, setProjectsList] = useState([]);

  const defaultBody = useMemo(() => {
    if (typeOfCarbon === 'denied') return deniedCarbonBodyRequest;
    if (typeOfCarbon === 'approve') return approveCarbonBodyRequest;
    return newCarbonBodyRequest;
  }, []);
  const getData = (data) => {
    if (data?.hits?.hits) {
      return data?.hits?.hits.map((el) => ({ ...el?._source, fields: el?.fields }));
    }
    return [];
  };
  const setProjects = (data) => {
    const res = getData(data);
    setProjectsList(res);
  };

  const showError = () => {
    setIsError(true);
    setProjectsList([]);
  };

  const fetchProjects = async (filter, sort, from) => {
    let body = {};
    if (filter?.length) {
      // eslint-disable-next-line no-undef
      const copyBody = structuredClone(defaultBody);
      const mustQuery = [...copyBody.query.bool.must, ...filter];
      copyBody.query.bool.must = mustQuery;
      body = copyBody;
    } else body = defaultBody;
    body.sort = [{ [sort.key]: sort.value }];
    body.from = from;
    const result = await sendRequest(indexerApi, 'POST', JSON.stringify(body));
    return result;
  };

  const initialize = async () => {
    setIsLoading(true);
    const { result, statusCode } = await fetchProjects(filterQuery, activeSort, 0);
    handleResponseStatus(
      statusCode,
      () => setProjects(result),
      () => showError(),
    );
    setIsLoading(false);
  };

  const extendProjects = (data) => {
    const res = getData(data);
    setProjectsList([...projectsList, ...res]);
  };

  const loadMore = async () => {
    const { result, statusCode } = await fetchProjects(filterQuery, activeSort, projectsList.length);
    handleResponseStatus(
      statusCode,
      () => extendProjects(result),
      () => showError(),
    );
  };

  useEffect(() => {
    (async () => {
      await initialize();
    })();
  }, [filterQuery, activeSort]);

  return { isLoading, isError, projectsList, loadMore, refreshList: initialize };
};

export default useProjectsList;
