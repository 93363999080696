import React, { useEffect, useState } from 'react';
import getConfig from '../../../config';
import { getFilesFromDirWithContent, initIPFS } from '../../../state/ipfs';

const ProjectDocumentation = ({ iconCidDir = '', filesCidDir }) => {
  const { ipfsGateway } = getConfig();
  const [filesList, setFilesList] = useState([]);

  const getFilesList = async (hash, signal) => {
    try {
      const ipfs = await initIPFS();
      const updateArray = await getFilesFromDirWithContent(ipfs, hash, signal);
      setFilesList(updateArray.map((el) => {
        const convertedFile = new File([el.content], el.path);
        // Additional helper field
        convertedFile.path = el.path;
        return convertedFile;
      }));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (filesCidDir) {
      getFilesList(filesCidDir);
    }
  }, []);

  return (
    <div className="eval__info">
      <div className="eval__info-smallImgBlock">
        <h3 className="eval__info-title">
          Cover Photo
        </h3>
        <div className="eval__info-smallImgBlock-imgSize">
          <img src={`${ipfsGateway}${iconCidDir}`} alt="Large Cover" />
        </div>
      </div>
      <div className="eval__info-documentation">
        <h3 className="eval__info-title">
          Project Documentation
        </h3>
        {filesCidDir ? filesList.length ? (
          <ul>
            {filesList.map((item, index) => (
              <li key={`${item.path}${index + Date.now()}`}>
                <a href={`${ipfsGateway}${filesCidDir}/${item.path}`} target="_blank" rel="noreferrer">
                  {index + 1}
                  .
                  {' '}
                  {item.path}
                </a>
              </li>
            ))}
          </ul>
        ) : <span className="project__loading-text">Loading...</span>
          : (
            <p className="eval__info-text">
              Not documents
            </p>
          )}
      </div>
    </div>
  );
};

export default ProjectDocumentation;
