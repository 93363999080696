import React, { useState } from 'react';
import CustomInput from '../../../generic/CustomInput';
import CustomCheckbox from '../../../generic/CustomCheckbox';
import DownloadZone from '../../../components/DownloadZone';

const BaselineList = ({ zones, register, setZones, errors, clearErrors, setValue, projectData, defaultState,
  setDefaultState }) => {
  const [defaultNumber, setDefaultNumber] = useState('');
  return (
    <div>
      <div className="leakage__block">
        <div className="leakage__form-block col">
          <div className="leakage__baseline-title">
            <span>Baseline (tCO2e/ha/y) </span>
            <DownloadZone customClass="leakage__download-zone" coordinate={projectData} buttonText="Download Monitoring zones" fileName={`ofp_${projectData.name}_nftZone`} />
          </div>
          <div className="leakage__baseline">
            <CustomInput
              placeholder="123"
              label="Default value (tCO2e/ha/y)"
              onChange={(e) => {
                clearErrors('baselineDefaultValue');
                setDefaultNumber(e.target.value);
                setDefaultState({ ...defaultState, baselineDefaultValue: e.target.value });
              }}
              onlyNumber
              register={() => register('baselineDefaultValue', { required: true })}
              type="text"
              name="baselineDefaultValue"
              value={defaultState.baselineDefaultValue}
              error={errors.baselineDefaultValue}
              customClassName="leakage__carbon-input leakage__lec-input  "
            />

          </div>

          <div className="leakage__block">
            {zones.length > 0 && zones.map((el, index) => {
              const nameError = `MZ ${index + 1}`;
              const error = errors?.zones?.[nameError];
              return (
                <div className="leakage__baseline" key={nameError}>
                  <CustomInput
                    placeholder="123"
                    register={() => register(`zones.MZ ${index + 1}`, { required: true })}
                    label={`MZ ${index + 1}`}
                    onChange={(e) => {
                      clearErrors(`zones.MZ ${index + 1}`);
                      setZones(zones.map((zone, count) => {
                        if (index === count) {
                          setValue(`zones.MZ ${index + 1}`, e.target.value);
                          return { ...zone, value: e.target.value };
                        }
                        return zone;
                      }));
                    }}
                    error={error}
                    onlyNumber
                    name={`zones.MZ ${index + 1}`}
                    value={zones[index].value}
                    customClassName="leakage__carbon-input leakage__lec-input"
                  />
                  <CustomCheckbox
                    type="checkbox"
                    onChange={(e) => setZones(zones.map((zone, count) => {
                      if (index === count && e.target.checked) {
                        clearErrors(`zones.MZ ${index + 1}`);
                        setValue(`zones.MZ ${index + 1}`, e.target.value);
                        return { ...zone, value: e.target.value };
                      }
                      return zone;
                    }))}
                    value={defaultNumber}
                    title="Use default value"
                    description=""
                    key="yes"
                    customClass="leakage__checkbox leakage__checkbox-zone"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaselineList;
