import React from 'react';
import { ReactComponent as Logo } from '../../../assets/headerLogo.svg';

const HeaderSteps = () => (
  <div className="eval__header">
    <Logo />
  </div>
);

export default HeaderSteps;
