import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import tokml from 'tokml';
import shpwrite from 'shp-write';
import Button from '../../generic/Button';
import CustomCheckbox from '../../generic/CustomCheckbox/CustomCheckbox';

const DownloadZone = ({ customClass, coordinate, buttonText, showPosition, fileName, downloadAll = false }) => {
  const [geojsonType, setGeojsonType] = useState(false);
  const [kmlType, setKmlType] = useState(false);
  const [kmzType, setKmzType] = useState(false);
  const [shpType, setShpType] = useState(false);

  const dataFor = `download-zone${Math.floor(Math.random() * 1e9)}`;

  const projectTypes = [
    {
      value: '.geojson',
      handler(e) {
        setGeojsonType(e.target.checked);
      },
    },
    {
      value: '.kml',
      handler(e) {
        setKmlType(e.target.checked);
      },
    },
    {
      value: '.kmz',
      handler(e) {
        setKmzType(e.target.checked);
      },
    },
    {
      value: '.shp',
      handler(e) {
        setShpType(e.target.checked);
      },
    },
  ];

  const createBlob = (data, type) => new Blob([data], { type });

  const addToZip = async (zip, zone, name) => {
    if (geojsonType) {
      const geoJsonFile = createBlob(JSON.stringify(zone), 'application/json');
      zip.file(`${name}.geojson`, geoJsonFile);
    }

    if (kmlType) {
      const kml = tokml(zone);
      const kmlFile = createBlob(kml, 'text/xml');
      zip.file(`${name}.kml`, kmlFile);
    }

    if (kmzType) {
      const kml = tokml(zone);
      const kmlFile = createBlob(kml, 'text/xml');

      const kmzZip = new JSZip();
      kmzZip.file(`${name}.kml`, kmlFile);
      const kmzFile = await kmzZip.generateAsync({ type: 'blob' });
      zip.file(`${name}.kmz`, kmzFile);
    }

    if (shpType) {
      const z = zone.features[0];
      shpwrite.write([z.properties], z.geometry.type.toUpperCase(), [z.geometry.coordinates], (err, files) => {
        if (err) return;

        const shpFiles = createBlob(files.shp, 'application/octet-stream');
        zip.file(`${name}.shp`, shpFiles);
      });
    }
  };

  const downloadProjectZone = async (polygon) => {
    const zip = new JSZip();

    const zone = {
      type: 'FeatureCollection',
      features: polygon,
    };
    await addToZip(zip, zone, fileName);

    const content = await zip.generateAsync({ type: 'blob' });
    FileSaver.saveAs(content, `${fileName}.zip`);
  };

  const downloadAllZones = async (subZones) => {
    if (!subZones?.length) return;

    const zip = new JSZip();

    for (const [i, sub] of Object.entries(subZones)) {
      const zone = {
        type: 'FeatureCollection',
        features: sub.polygon,
      };
      // eslint-disable-next-line no-await-in-loop
      await addToZip(zip, zone, `ofp_${coordinate.name}_monitoring_${i}`);
    }

    const content = await zip.generateAsync({ type: 'blob' });
    FileSaver.saveAs(content, `${fileName}.zip`);
  };

  const handleDownload = async () => {
    try {
      if (!geojsonType && !kmlType && !kmzType && !shpType) return;

      if (downloadAll) {
        await downloadAllZones(coordinate.subZones);
        return;
      }

      await downloadProjectZone(coordinate.polygon);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="download-zone">
      <div className="download-zone__btn-wrapper" data-tip data-for={dataFor} data-iscapture="true">
        <Button
          className={`download-zone__btn ${customClass}`}
          onClick={handleDownload}
        >
          {buttonText}
        </Button>
      </div>
      <ReactTooltip
        className="download-zone__list"
        id={dataFor}
        effect="solid"
        clickable
        place={showPosition}
        delayHide={300}
        backgroundColor="white"
        border
        borderColor="#d5d9e7"
        isCapture
      >
        {projectTypes.map(({ value, handler }) => (
          <CustomCheckbox
            type="checkbox"
            onChange={handler}
            value={value}
            title={value}
            description=""
            key={value}
            customClass="checkbox__checkbox"
          />
        ))}
      </ReactTooltip>
    </div>
  );
};

export default DownloadZone;
