import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import agree from '../../assets/galka.svg';
import copy from '../../assets/coolicon.svg';

const CopyEmail = ({ email }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (show) {
      setTimeout(() => setShow(false), 2000);
    }
  }, [show]);

  return (
    <div className="email">
      <div
        className="email__btn"
        onClick={() => {
          setShow(true);
        }}
      >
        <CopyToClipboard text={email}>

          <span>Copy Email</span>
        </CopyToClipboard>
        <ReactSVG src={copy} />

      </div>
      {show && (
      <div className="email__popup">
        <span>Email successfully copied</span>
        <ReactSVG src={agree} />
      </div>
      )}
    </div>
  );
};

export default CopyEmail;
