import React from 'react';
import Select, { components } from 'react-select';
import CustomCheckbox from '../CustomCheckbox';

const CustomSelect = ({ value, customClassName, ref, placeholder, classSelect, withCheck, optionArray, label, error, required, change, classError, customClassLabel = '', disabled }) => {
  const customLabelClass = `${required ? 'required' : ''} select__label ${customClassLabel}`;
  const classInput = `${error ? `error ${classError}` : ''}`;
  const Option = (props) => {
    const { data } = props;
    const activeClass = data.value === value ? 'active' : '';
    return (
      <div>
        <components.Option {...props} className="select__option">
          <div className={`${activeClass} select__toolkit`}>
            <span>{data.label}</span>
            <CustomCheckbox
              type="checkbox"
              onChange={() => {
              }}
              value="0"
              description=""
              key="yes"
              disabled={disabled}
              customClass="leakage__checkbox"
              checked={data.value === value}
            />
          </div>
        </components.Option>
      </div>
    );
  };
  const currentIndexOption = optionArray.findIndex((el) => el.value === value);

  return (
    <div className={`select ${customClassName}`}>
      {label && <span className={customLabelClass}>{label}</span>}
      {withCheck ? (
        <Select
          isDisabled={disabled}
          className={`${classSelect} ${classInput}`}
          defaultValue={optionArray[currentIndexOption]}
          placeholder={placeholder}
          options={optionArray}
          inputRef={ref}
          isSearchable={false}
          onChange={(e) => change(e)}
          classNamePrefix="select-react"
          components={{ Option }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 12,
            border: '1px solid #EEF0F5',
            colors: {
              ...theme.colors,
              primary25: 'transparent',
              primary: 'transparent',
              neutral50: '#CFD6E4',
              primary75: 'transparent',
              primary50: 'transparent',
            },
          })}
        />
      ) : (
        <Select
          className={classInput}
          defaultValue={value}
          placeholder={placeholder}
          options={optionArray}
          isDisabled={disabled}
          isSearchable={false}
          classNamePrefix="select-react"
          onChange={(e) => change(e)}
          inputRef={ref}
        />
      )}
    </div>
  );
};

export default CustomSelect;
