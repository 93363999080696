import React, {
  useEffect, useState, useCallback,
} from 'react';
import debounce from 'lodash.debounce';
import { useInView } from 'react-intersection-observer';
import Modal from '../Modal/Modal';
import Button from '../../generic/Button';
import SearchInput from '../SearchInput';
import ValidatorRegistrationForm from '../ValidatorRegistrationForm/ValidatorRegistrationForm';
import TableItemValidator from '../Tabs/TableItemValidator';
import TableColumnNames from '../TableColumnNames';
import { validatorsTableNames } from '../../utils/table-constants';
import getValidators from '../../services/getValidators';
import Loader from '../Loader';

const Validators = () => {
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [filteredValidatorsList, setFilteredValidatorsList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [moreIsLoading, moreSetIsLoading] = useState(false);

  const onSearch = useCallback(debounce(async (searchParams) => {
    const target = searchParams?.toLowerCase().trim();
    const validators = await getValidators(target, 0);
    if (validators?.statusCode === 200) {
      if (validators?.result?.length === 0) {
        setFilteredValidatorsList(null);
        return;
      }
      setFilteredValidatorsList(validators?.result);
    }
  }, 500), []);
  const loadMore = async (searchParams) => {
    if (filteredValidatorsList.length && !searchValue) {
      moreSetIsLoading(true);
      const target = searchParams?.toLowerCase().trim();
      const validators = await getValidators(target, filteredValidatorsList?.length);
      if (validators?.statusCode === 200) {
        if (validators?.result) {
          setFilteredValidatorsList((prevState) => [...prevState, ...validators?.result]);
        }
      }
      moreSetIsLoading(false);
    }
  };
  useEffect(() => {
    onSearch(searchValue);
  }, [searchValue]);
  const { ref } = useInView({
    root: null,
    threshold: 0,
    onChange: (inView) => {
      if (inView) {
        loadMore();
      }
    },
  });
  return (
    <>
      <div className="validators__top-block">
        <span className="validators__title">List of Validators</span>
        <div className="validators__buttons">
          <SearchInput onChange={setSearchValue} value={searchValue} />
          <Button className="validators__button" onClick={() => setShowModalRegister(true)}>
            <span className="validators__button-title">Register Validators</span>
          </Button>
        </div>
      </div>
      <div className="validators__list">
        <TableColumnNames names={validatorsTableNames} />
        {!filteredValidatorsList?.length && <span className="validators__phase">Validators not found.</span>}
        {filteredValidatorsList?.map((el, index, array) => (
          <TableItemValidator
            isLast={array.length - 1 === index}
            email={el?.email}
            itemRef={ref}
            accountId={el?.near_account_id}
            key={el?.id}
          />
        ))}
        {moreIsLoading && (
        <div className="table__loader-more">
          <Loader />
        </div>
        )}
      </div>
      <Modal show={showModalRegister} close={() => setShowModalRegister(false)}>
        <ValidatorRegistrationForm setShowModal={setShowModalRegister} />
      </Modal>
    </>
  );
};

export default Validators;
