import React from 'react';

const ProjectsOnProd = ({ active }) => {
  const fillColor = active ? '#329D9C' : '#CECECE';
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 20H2C0.89543 20 0 19.1046 0 18V6H2V18H14V20ZM18 16H6C4.89543 16 4 15.1046 4 14V2C4 0.89543 4.89543 0 6 0H18C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16ZM6 2V14H18V2H6ZM11 11.561L7.293 7.853L8.707 6.439L11 8.733L15.293 4.44L16.707 5.854L11 11.56V11.561Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ProjectsOnProd;
