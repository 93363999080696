import React from 'react';

const Button = ({ children, onClick, className, disabled, type }) => (
  <button
    type={type ? 'submit' : 'button'}
    className={`btn ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;
