import React from 'react';
import CustomSwitch from '../../generic/CustomSwitch';

const SwitchField = ({ label, register, value, onChange }) => (
  <div className="switch-field__container">
    <span className="switch-field__label">{label}</span>
    <CustomSwitch register={register} value={value} onChange={onChange} />
  </div>
);

export default SwitchField;
