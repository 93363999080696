import React, { useState } from 'react';
import Button from '../../generic/Button';
import SearchInput from '../../generic/CustomInput';
import ColumnNamesPermissions from '../ColumnNamesPermissions';
import TableItemPermissions from '../Tabs/TableItemPermissions';
import Modal from '../Modal/Modal';
import { permissionsModalTypes } from '../../utils/constants';
import UserRegistrationForm from '../UserRegistrationForm';
import ActionConfirmation from '../ActionConfirmation';

const Permissions = () => {
  const { registration, changing, removing } = permissionsModalTypes;
  const [modalState, setModalState] = useState({ show: false, type: null });

  const renderModalContent = () => {
    const { type } = modalState;

    switch (type) {
      case registration:
        return <UserRegistrationForm setModalState={setModalState} />;
      case removing:
        return (
          <ActionConfirmation
            question="Are you sure you want to delete User?"
            onDecline={() => setModalState({ show: false, type: null })}
          />
        );
      case changing:
        return (
          <ActionConfirmation
            question="You confirm the changes on the page Permissions?"
            onDecline={() => setModalState({ show: false, type: null })}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="container">
      <div className="permissions__top-block">
        <span className="permissions__title">Permissions</span>
        <div className="permissions__buttons">
          <SearchInput customClassName="permissions__search" />
          <Button className="permissions__button" onClick={() => setModalState({ show: true, type: registration })}>
            <span className="permissions__button-title">Register User</span>
          </Button>
        </div>
      </div>
      <div className="list">
        <ColumnNamesPermissions />
        <TableItemPermissions setModalState={setModalState} />
      </div>
      <Modal show={modalState.show} close={() => setModalState({ show: false, type: null })} wide>
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default Permissions;
