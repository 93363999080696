import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ValidationConstants } from '../../utils/constants';
import Button from '../../generic/Button';
import CustomInput from '../../generic/CustomInput';
import NearLogo from '../../assets/nearLogo.svg';
import SwitchField from '../SwitchField';

const UserRegistrationForm = ({ setModalState }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    defaultValues: {
      contract_management: false,
      profile_management: false,
      project_management: false,
      carbon_management: false,
    },
  });

  const onSubmit = async (data) => {
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <form className="registration">
      <span className="registration__title">Register new User</span>
      <div className="registration__account">
        <img className="registration__logo" src={NearLogo} alt="logo" />
        <CustomInput
          type="text"
          label="NEAR account ID"
          register={() => register('near_account_id', {
            required: 'NEAR account ID is required.',
            pattern: {
              value: ValidationConstants.NEAR_ACCOUNT_ID,
              message: 'Please enter an id account in the format username.near.',
            },
          })}
          onChange={(e) => { setValue('near_account_id', e.target.value); }}
          error={errors.near_account_id}
          placeholder="NEAR account ID"
        />
      </div>

      <Controller
        control={control}
        name="contract_management"
        defaultValue={false}
        render={({ field: { value, onChange } }) => (
          <SwitchField label="Smart contract management" value={value} onChange={onChange} />
        )}
      />

      <Controller
        control={control}
        name="profile_management"
        defaultValue={false}
        render={({ field: { value, onChange } }) => (
          <SwitchField label="Profile management" value={value} onChange={onChange} />
        )}
      />

      <Controller
        control={control}
        name="project_management"
        defaultValue={false}
        render={({ field: { value, onChange } }) => (
          <SwitchField label="Change project settings" value={value} onChange={onChange} />
        )}
      />

      <Controller
        control={control}
        name="carbon_management"
        defaultValue={false}
        render={({ field: { value, onChange } }) => (
          <SwitchField label="Carbon approval/cancelling" value={value} onChange={onChange} />
        )}
      />

      <div className="registration__submit-container">
        <Button className="registration__cancel" onClick={() => setModalState({ show: false, type: null })}>
          <span className="registration__button-text">Cancel</span>
        </Button>
        <Button type="submit" className="registration__submit" onClick={handleSubmit(onSubmit)}>
          <span className="registration__button-text">Register new User</span>
        </Button>
      </div>
    </form>
  );
};

export default UserRegistrationForm;
