import React from 'react';

const CustomCheckbox = ({ customClass, type, onChange, value, title, description, register, name, checked, disabled }) => {
  const classMain = `checkbox ${disabled ? 'disabled' : ''} ${customClass || ''}`;

  // TODO fix semantic nesting of the label element
  return (
    <label className={classMain}>
      <div className="checkbox__checkbox-info">
        <span className="checkbox__checkbox-title">{title}</span>
        <span className="checkbox__secondary-txt">{description}</span>
      </div>
      {register ? (
        <input
          disabled={disabled}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          ref={register}
          checked={checked}
        />
      ) : (
        <input
          type={type}
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
        />
      )}
      <span className="checkmark" />
    </label>
  );
};

export default CustomCheckbox;
