import React from 'react';
import FieldText from '../../generic/FieldText';
import CustomCheckbox from '../../../../generic/CustomCheckbox';
import Button from '../../../../generic/Button';

const Laf = ({ step, setStep, data }) => {
  if (step !== 4) {
    return null;
  }

  const {
    needGovernmentApprove = '',
    envImpactAssessment = '',
    summaryOfImpacts = '',
    areActivitiesLegalRequirement = '',
    activitiesRequirementExplanation = '',
    isTimberHarvestingPlanned = '',
    timberHarvestingExplanation = '',
    projectFinancing = '',
    financingReceived = '',
    enoughFundingToProtect = '',
    viableWithoutCarbonFinancing = '',
    willOFPHelp = '',
    howOFPHelps = '',
  } = data?.carbonFormInfo?.legalAndFinancial;

  return (
    <div className="eval__block">
      <div className="eval__gen-checkboxs">
        <span>4.1 Does the project need to be approved by the government to comply with national laws and regulations?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(needGovernmentApprove) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(needGovernmentApprove)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(needGovernmentApprove) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(needGovernmentApprove)}
        />
      </div>
      <div className="eval__gen-checkboxs">
        <span>4.2 Is an Environmental Impact Assessment legally required?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(envImpactAssessment) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(envImpactAssessment)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(envImpactAssessment) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(envImpactAssessment)}
        />
      </div>
      <FieldText
        label="4.3 If yes, please provide a summary of the impacts and mitigation measures."
        value={summaryOfImpacts}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <div className="eval__gen-checkboxs">
        <span>4.4 In the project zone, are reforestation / afforestation / revegetation / conservation activities a legal requirement (required by law, regulatory framework, etc.) ?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(areActivitiesLegalRequirement) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(areActivitiesLegalRequirement)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(areActivitiesLegalRequirement) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(areActivitiesLegalRequirement)}
        />
      </div>
      <FieldText
        label="4.5 If yes, please explain."
        value={activitiesRequirementExplanation}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <div className="eval__gen-checkboxs">
        <span>4.6 Is timber harvesting planned in the project zone?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(isTimberHarvestingPlanned) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(isTimberHarvestingPlanned)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(isTimberHarvestingPlanned) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(isTimberHarvestingPlanned)}
        />
      </div>
      <FieldText
        label="4.7 If yes, please explain in more details what is planned in terms of timber harvesting."
        value={timberHarvestingExplanation}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <FieldText
        label="4.8 How is the project financed ?"
        value={projectFinancing}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <FieldText
        label="4.9 How much financing has been received up until now and for what activities? Please indicate the currency used."
        value={financingReceived}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <FieldText
        label="4.10 Is there enough funding secured to protect the forest over the long term (more than to 40 years) without taking into account the revenue from carbon credits?"
        value={enoughFundingToProtect}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <FieldText
        label="4.11 Can the project be economically and financially viable without access to carbon finance? Please explain."
        value={viableWithoutCarbonFinancing}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <div className="eval__gen-checkboxs">
        <span>4.12 Will the use of Open Forest Protocol help your project overcome a potential technological barrier to implementing or continuing this project?</span>
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="yes"
          title="yes"
          description=""
          key="yes"
          customClass={JSON.parse(willOFPHelp) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={JSON.parse(willOFPHelp)}
        />
        <CustomCheckbox
          type="checkbox"
          onChange={() => {}}
          value="no"
          title="no"
          description=""
          key="no"
          customClass={!JSON.parse(willOFPHelp) ? 'eval__checkbox ' : 'eval__checkbox not-use'}
          checked={!JSON.parse(willOFPHelp)}
        />
      </div>
      <FieldText
        label="4.13 Description of how OFP helps this project overcome a technological barrier."
        value={howOFPHelps}
        customClass="full not-out full not-out eval__activity-field textarea"
      />
      <div className="eval__wrapper-btns">
        <Button className="cancel" onClick={() => setStep(3)}>Back </Button>
        <Button onClick={() => setStep(5)}>Next</Button>
      </div>
    </div>
  );
};

export default Laf;
