import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { appStore } from '../../state/app';
import { checkingLogin } from '../../helpers/login';
import Button from '../../generic/Button';
import Logo from '../../assets/OFT-logo.svg';
import { storageKeys } from '../../utils/constants';
import Footer from '../../layouts/Footer';

const Login = () => {
  const history = useHistory();
  const { state, update } = useContext(appStore);
  const { wallet, account, app } = state;
  const { errors } = app;

  useEffect(() => {
    async function check() {
      if (wallet && wallet.signedIn) {
        await checkingLogin(account, history, () => {}, false, update);
      }
    }
    check();
  }, [wallet]);

  return (
    <div className="login">
      <div className="inner-wrapper">
        <div className="logo-block">
          <img src={Logo} alt="logo" />
          <h1 className="title">
            Welcome to the
            <br />
            Open Forest Protocol Admin Dashboard
          </h1>
        </div>
        <Button
          className="login-btn"
          onClick={() => {
            localStorage.clear();
            wallet.signIn();
            localStorage.setItem(storageKeys.isSignIn, true);
          }}
        >
          Login with NEAR wallet
        </Button>
        {errors && (
        <h3 className="message">
          User
          {' '}
          {account?.accountId}
          {' '}
          has no admin right
        </h3>
        )}
      </div>
      <Footer customClass="absolute-left" />
    </div>
  );
};

export default Login;
