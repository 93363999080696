import React, {
  useEffect, useState, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import Login from './pages/Login';
import Evaluate from './pages/Evaluate';
import Layout from './layouts/Layout';
import Leakage from './pages/Leakage';
import EditLeakage from './pages/EditLeakage';
import { sendRequest } from './utils/api-request';
import getConfig from './config';
import { checkingLogin, expireToken } from './helpers/login';
import { storageKeys } from './utils/constants';
import { appStore } from './state/app';
import RenderRoutes from './components/RenderRoutes';
import Home from './pages/Home';

const ProtectRoute = ({ children }) => {
  const history = useHistory();
  const [returnElem, setReturnElem] = useState('');
  const { backendApi } = getConfig();
  const { state, update } = useContext(appStore);
  const { account } = state;
  useEffect(() => {
    async function checkPassToApp() {
      const token = localStorage.getItem(storageKeys.token);
      if (token) {
        if (expireToken(token)) {
          localStorage.setItem(storageKeys.isSignIn, true);
          await checkingLogin(account, history, () => {}, false, update);
          return setReturnElem(children);
        }
        const { statusCode } = await sendRequest(`${backendApi}/api/admin/dashboard/project-operators?limit=10`, 'GET', {}, true);
        if (statusCode === 401 && !!account) {
          localStorage.setItem(storageKeys.isSignIn, true);
          await checkingLogin(account, history, () => {}, false, update);
        }
        setReturnElem(children);
      } else {
        history.push('/login');
      }
    }
    checkPassToApp();
  }, []);
  return returnElem;
};
const routes = [
  {
    path: '/login',
    key: 'ROOT',
    exact: true,
    component: () => (
      <Login />
    ),
  },
  {
    path: '/evaluate/:id',
    key: 'ROOT',
    exact: true,
    component: () => (
      <ProtectRoute>
        <Evaluate />
      </ProtectRoute>
    ),
  },
  {
    path: '/leakage/:id',
    key: 'ROOT',
    exact: true,
    component: () => (
      <ProtectRoute>
        <Leakage />
      </ProtectRoute>
    ),
  },
  {
    path: '/edit-leakage/:id',
    key: 'ROOT',
    exact: true,
    component: () => (
      <ProtectRoute>
        <EditLeakage />
      </ProtectRoute>
    ),
  },
  {
    path: '/',
    key: 'ROOT',
    component: ({ ...props }) => (
      <ProtectRoute>
        <Layout>
          <RenderRoutes {...props} />
        </Layout>
      </ProtectRoute>
    ),
    routes: [
      {
        path: '/',
        exact: true,
        key: 'ROOT',
        component: () => (
          <Home page="ApproveOrganizations" />
        ),
      },
      {
        path: '/validators',
        key: 'ROOT',
        component: () => (
          <Home page="Validators" />
        ),
      },
      {
        path: '/protocol-info',
        exact: true,
        key: 'ROOT',
        component: () => (
          <Home page="ProtocolInfo" />
        ),
      },
      {
        path: '/carbon-project',
        key: 'ROOT',
        component: () => (
          <Home page="ApproveCarbonProjects" />
        ),
      },
    ],
  },

];

export default routes;
