import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import TabImg from '../../components/Tabs/TabImg';
import { navigationMenu } from '../../utils/constants';
import Footer from '../Footer';

const Menu = () => {
  const location = useLocation();

  return (
    <div className="menu">
      <nav className="nav">
        {navigationMenu.map((item) => (
          <NavLink
            activeClassName="active"
            className="menu-link"
            key={item.name}
            to={item.route}
            exact
          >
            <div className="menu-link_img">
              <TabImg name={item.name} active={location?.pathname === item.route} />
            </div>
            {item.name}
          </NavLink>
        ))}
      </nav>
      <Footer customClass="inMenu" />
    </div>
  );
};
export default Menu;
