import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { appStore } from '../../state/app';
import Logo from '../../assets/headerLogo.svg';

const Header = () => {
  const history = useHistory();
  const { state } = useContext(appStore);
  const { wallet } = state;
  const signOut = state?.wallet?.signedIn;

  return (
    <header className="header">
      <img className="logo" src={Logo} alt="logo" />
      {signOut && (
        <button
          type="button"
          className="btn_sign-out"
          onClick={() => {
            wallet.signOut();
            history.push('/login');
          }}
        >
          Log out
        </button>
      )}
    </header>
  );
};

export default Header;
