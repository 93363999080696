import * as nearAPI from 'near-api-js';
import { Near, keyStores } from 'near-api-js';
import getConfig from '../config';

export const { networkId, nodeUrl, walletUrl, contractName, contractMethods, marketDeposit } = getConfig();

const { Account } = nearAPI;

export const formatAccountId = (accountId, len = 16) => {
  if (accountId.length > len) {
    return `${accountId.substr(0, len - 3)}...`;
  }
  return accountId;
};

export const getContract = (account, methods = contractMethods, contractId) => new nearAPI.Contract(account, contractName[contractId], {
  ...methods,
  sender: account.accountId,
});

export const getWallet = async () => {
  const near = await nearAPI.connect({
    networkId,
    nodeUrl,
    walletUrl,
    deps: { keyStore: new nearAPI.keyStores.BrowserLocalStorageKeyStore() },
  });
  const wallet = new nearAPI.WalletAccount(near);
  const contractAccount = new Account(near.connection, contractName[0]);
  return { near, wallet, contractAccount };
};

export const postJson = async ({ url, data = {} }) => {
  const result = fetch(url, {
    method: 'POST',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify({ ...data }),
  }).then((res) => res.json());
  return result;
};

const config = getConfig();
export const keyStore = new keyStores.BrowserLocalStorageKeyStore();

export const near = new Near({
  keyStore,
  headers: {},
  ...config,
});

export const getPKey = (id) => {
  const iterableKeys = Object.keys(localStorage);
  const keyCurrent = iterableKeys.find((el) => el.includes(`near-api-js:keystore:${id}`));
  const storageKey = localStorage.getItem(keyCurrent);
  return storageKey;
};

export const getTxHashes = () => {
  const search = window.location.search;
  const txHashes = new URLSearchParams(search).get('transactionHashes');
  return txHashes;
};

export const getHexTxFromUrl = (txHashes) => {
  const hashes = txHashes.split(',');
  return hashes.length > 1 ? hashes[hashes.length - 1] : hashes[0];
};

export const checkTransaction = (txHash, accId) => near.connection.provider.sendJsonRpc('EXPERIMENTAL_tx_status', [txHash, accId]);

export const methodsNames = {
  register_account_with_permissions: 'add_validation_profile',
  storage_deposit: 'storage_deposit',
  confirm: 'confirm',
  update_project_carbon: 'update_project_carbon',
};
