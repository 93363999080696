import React, { useEffect } from 'react';

const stopEventPropagation = (e) => {
  if (e.target === e.currentTarget) {
    e.stopPropagation();
    return e;
  }
};

const CustomPopup = ({ children, showPopUp, setShowPopUp, widthParam }) => {
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className="wrapper-popup" onClick={(e) => stopEventPropagation(e) && setShowPopUp(!showPopUp)}>
      <div className={`wrapper-popup-card ${widthParam || 'defaultWidth'}`}>
        {children}
      </div>
    </div>
  );
};

export default CustomPopup;
