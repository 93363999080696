import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import getProjects from '../../services/getProjects';
import ListItem from '../ListItem/ListItem';
import verifyProject from '../../services/verifyProject';
import { storageKeys } from '../../utils/constants';
import Loader from '../Loader';

const List = ({ type, verified }) => {
  const [projects, setProjects] = useState([]);
  const [moreIsLoading, moreSetIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem(storageKeys.token);
      if (token) {
        const projData = await getProjects((type || ''), projects.length);
        setProjects(projData);
      }
    })();
  }, []);
  const loadMore = async () => {
    if (projects.length) {
      moreSetIsLoading(true);
      const projData = await getProjects((type || ''), projects.length);
      if (projData) {
        setProjects((prevState) => [...prevState, ...projData]);
      }
      moreSetIsLoading(false);
    }
  };
  const projectVerifing = async (status, id, comment) => {
    await verifyProject({
      approve: status,
      project_operator_id: id,
      comment,
    });

    const newProjects = projects.filter((item) => item.id !== id);
    setProjects(newProjects);
  };
  const { ref } = useInView({
    root: null,
    threshold: 0,
    onChange: (inView) => {
      if (inView) {
        loadMore();
      }
    },
  });

  return (
    <>
      {projects?.map((item, index, array) => (
        <ListItem
          {...item?.organization}
          ap_or_re_comment={item.ap_or_re_comment}
          count={index + 1}
          id={item?.id}
          key={item?.id}
          itemRef={ref}
          isLast={array.length - 1 === index}
          verified={verified}
          projectVerifing={projectVerifing}
          near_account_id={item?.near_account_id}
          verifiedStatus={item?.verified}
          verifiedDate={item?.verified_date}
        />
      ))}
      {moreIsLoading && (
      <div className="table__loader-more">
        <Loader />
      </div>
      )}
    </>

  );
};

export default List;
