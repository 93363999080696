import React from 'react';

import Button from '../../../../generic/Button';
import FieldBlock from '../../generic/FieldBlock/FieldBlock';
import { risksParams } from '../../../../constans/constans';

const Risks = ({ step, setStep, data }) => {
  if (step !== 5) {
    return null;
  }
  const {
    natural = {},
    socialAndPolitical = {},
    projectManagement = {},
    financial = {},
  } = data?.carbonFormInfo?.risks;
  const {
    fire = { },
    pestsAndDiseases = { },
    extremeEvents = { },
    extremeDroughts = { },
    animalEncroachment = { },
  } = natural;
  const {
    disputeOverLandTenureOrOwnership = {},
    politicalAndSocialInstability = { },
    exploitationOfNaturalResources = { },
    lackOfCommunitySupport = {},
  } = socialAndPolitical;
  const {
    insufficientTechnicalCapacities = { },
    lackOfEquipmentAndMaterial = { },
    lackOfProjectManagement = { },
    relianceOnKeyEmployees = { },
  } = projectManagement;
  const { lackOfSufficientFunding = {} } = financial;

  return (
    <div className="eval__block">
      <div className="eval__block">
        <h2 className="eval__risks-title">5.1 Natural risks</h2>
        <FieldBlock
          label="5.1.1 Damage of the project zone by a fire"
          valInput={risksParams[fire?.rating]}
          valArea={fire?.description}
        />
        <FieldBlock
          label="5.1.2 Damage of the project zone by pests and diseases"
          valInput={risksParams[pestsAndDiseases?.rating]}
          valArea={pestsAndDiseases?.description}
        />
        <FieldBlock
          label="5.1.3 Damage of the project zone by extreme events such as hurricanes, earthquakes, volcanoes, etc."
          valInput={risksParams[extremeEvents?.rating]}
          valArea={extremeEvents?.description}
        />
        <FieldBlock
          label="5.1.4 Damage of the project zone due to extreme droughts, floods, landslides, etc."
          valInput={risksParams[extremeDroughts?.rating]}
          valArea={extremeDroughts?.description}
        />
        <FieldBlock
          label="5.1.5 Damage of the project zone due to animal encroachment (wild and domestic animals)"
          valInput={risksParams[animalEncroachment?.rating]}
          valArea={animalEncroachment?.description}
        />
      </div>
      <div className="eval__block">
        <h2 className="eval__risks-title">5.2 Social and political risks</h2>
        <FieldBlock
          label="5.2.1 Project failure due to dispute over land tenure or ownership of land rights in the project zone (including expropriation risks)"
          valInput={risksParams[disputeOverLandTenureOrOwnership?.rating]}
          valArea={disputeOverLandTenureOrOwnership?.description}
        />
        <FieldBlock
          label="5.2.2 Project failure due to political and social instability such as wars, riots, corruption, community resistance"
          valInput={risksParams[politicalAndSocialInstability?.rating]}
          valArea={politicalAndSocialInstability?.description}
        />
        <FieldBlock
          label="5.2.3 Project failure due to exploitation of natural resources in or in the vicinity of the project zone (timber, mining, water, oil, etc.)"
          valInput={risksParams[exploitationOfNaturalResources?.rating]}
          valArea={exploitationOfNaturalResources?.description}
        />
        <FieldBlock
          label="5.2.4 Project failure due to lack of community support for the project"
          valInput={risksParams[lackOfCommunitySupport?.rating]}
          valArea={lackOfCommunitySupport?.description}
        />
      </div>
      <div className="eval__block">
        <h2 className="eval__risks-title">5.3 Project management</h2>
        <FieldBlock
          label="5.3.1 Project failure due to insufficient technical capacities (reliance on seasonal workers / workers needing constant training, etc.)"
          valInput={risksParams[insufficientTechnicalCapacities?.rating]}
          valArea={insufficientTechnicalCapacities?.description}
        />
        <FieldBlock
          label="5.3.2 Project failure due to lack of technical equipment and planting material (machinery, seedlings, etc.)"
          valInput={risksParams[lackOfEquipmentAndMaterial?.rating]}
          valArea={lackOfEquipmentAndMaterial?.description}
        />
        <FieldBlock
          label="5.3.3 Project failure due to lack of project management and long term financial planning skills"
          valInput={risksParams[lackOfProjectManagement?.rating]}
          valArea={lackOfProjectManagement?.description}
        />
        <FieldBlock
          label="5.3.4 Project failure due to the reliance of the project’s success on key employees that are hard to replace"
          valInput={risksParams[relianceOnKeyEmployees?.rating]}
          valArea={relianceOnKeyEmployees?.description}
        />
      </div>
      <div className="eval__block">
        <h2 className="eval__risks-title">5.4 Financial</h2>
        <FieldBlock
          label="5.4.1 Project failure due to the lack of sufficient funding to support the project activities until carbon finance can be received (3-4 years after the trees are planted)"
          valInput={risksParams[lackOfSufficientFunding?.rating]}
          valArea={lackOfSufficientFunding?.description}
        />
      </div>
      <div className="eval__wrapper-btns">
        <Button className="cancel" onClick={() => setStep(4)}>Back </Button>
        <Button onClick={() => setStep(6)}>Next</Button>
      </div>
    </div>
  );
};

export default Risks;
