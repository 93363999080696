import React from 'react';

const FieldText = ({
  label = '',
  value = '',
  customClass = '',
  labelClass = '',
  placeholder = '',
}) => (
  <div className={`eval__field ${customClass}`}>
    {label && <h3 className={`eval__field-label ${labelClass}`}>{label}</h3>}
    {value && <p className="eval__field-text">{value}</p>}
    {placeholder && <p className="eval__field-placeholder">{placeholder}</p>}
  </div>
);

export default FieldText;
