import React from 'react';
import { Buffer } from 'buffer';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './state/app';
import Main from './Main';

window.Buffer = window.Buffer || Buffer;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AppProvider>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </AppProvider>,
);
