import { clientApi } from './CLIENT';

const url = '/api/admin/dashboard/create-validator';
// eslint-disable-next-line no-return-await
export default async (data, successCallback, failureCallback) => await clientApi()
  .post(url, data)
  .then((res) => {
    // eslint-disable-next-line no-unused-expressions
    successCallback && successCallback();
    return res;
  })
  .catch(() => {
    // eslint-disable-next-line no-unused-expressions
    failureCallback && failureCallback();
  });
