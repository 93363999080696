/* eslint-disable no-console */
import { clientApi } from './CLIENT';

const url = '/api/admin/dashboard/';

// eslint-disable-next-line no-return-await
export default async (body) => await clientApi()
  .post(`${url}activate-maintenance-mode`, body)
  .then((data) => data.data)
  .catch((error) => console.log(error));
