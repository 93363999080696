import React from 'react';
import FieldText from '../../generic/FieldText';
import CustomCheckbox from '../../../../generic/CustomCheckbox';
import Button from '../../../../generic/Button';

const GeneralInfo = ({ step, setStep, data }) => {
  if (step !== 1) {
    return null;
  }
  const {
    isGhgCarbon = '',
    ghgCarbonPrograms = '',
    country = '',
    projectSize = 0,
    communitiesAndStakeholders = '',
    subOrTropicalTrees = '',
    treeDensity = 0,
    isRelativelyHomogeneous = '',
    positiveImpactsDescription = '',
    yearsInvolved = {},
    continent = {},
    mainTypeOfActivity = '',
    startPlantingActivities = {},
    durationForPlanting = {},
    forestType = {},
  } = data?.carbonFormInfo?.generalInfo;

  return (
    <div className="eval__gen">
      <div className="eval__block">
        <FieldText
          label="1.1 How long has the organisation been involved in forest activities (years)?"
          value={yearsInvolved?.value}
          customClass="half eval__gen-field most-text"
        />
        <div className="eval__gen-checkboxs">
          <span>1.2 Is the project registered (or seeking registration) under other GHG/carbon program(s) than Open Forest Protocol?</span>
          <CustomCheckbox
            type="checkbox"
            onChange={() => {}}
            value="yes"
            title="yes"
            description=""
            key="yes"
            customClass={JSON.parse(isGhgCarbon) ? 'eval__checkbox' : 'eval__checkbox not-use'}
            checked={JSON.parse(isGhgCarbon)}
          />
          <CustomCheckbox
            type="checkbox"
            onChange={() => {}}
            value="no"
            title="no"
            description=""
            key="no"
            customClass={!JSON.parse(isGhgCarbon) ? 'eval__checkbox' : 'eval__checkbox not-use'}
            checked={!JSON.parse(isGhgCarbon)}
          />
        </div>
        <FieldText
          label="1.3 If yes, under which program(s) ?"
          value={ghgCarbonPrograms}
          customClass="full eval__gen-field"
        />
        <FieldText
          label="1.4 In which continent is the project located?"
          value={continent?.label}
          customClass="half eval__gen-field"
        />
        <FieldText
          label="1.5 In which country is the project located?"
          value={country}
          customClass="half eval__gen-field"
        />
        <FieldText
          label="1.6 When did the tree planting activities start?"
          value={startPlantingActivities?.value}
          customClass="half eval__gen-field "
        />
        <FieldText
          label="1.7 What is the expected project duration for planting the trees, taking care of the project and monitoring the tree/forest growth? (years)"
          value={durationForPlanting?.value}
          customClass="half eval__gen-field most-text"
        />
        <FieldText
          label="1.8 What is the project size (ha)?"
          value={projectSize}
          customClass="half eval__gen-field"
        />
        <FieldText
          label="1.9 Has the project (design and implementation) been presented (including consultation) to local communities and stakeholders ? If yes, when and with how many participants?"
          value={communitiesAndStakeholders}
          customClass="full  not-out eval__gen-field textarea"
        />
        <FieldText
          label="1.10 What is the project’s forest type?"
          value={forestType?.label}
          customClass="half eval__gen-field"
        />
        <div className="eval__gen-checkboxs">
          <span>1.11 If the project is a tropical or subtropical forest, what is the main type of trees?</span>
          <CustomCheckbox
            type="checkbox"
            onChange={() => {}}
            value="pines"
            title="pines"
            description=""
            key="pines"
            customClass="eval__checkbox "
            checked={subOrTropicalTrees === 'pines'}
          />
          <CustomCheckbox
            type="checkbox"
            onChange={() => {}}
            value="broadleaf"
            title="broadleaf"
            description=""
            key="broadleaf"
            customClass="eval__checkbox not-use"
            checked={subOrTropicalTrees === 'broadleaf'}
          />
        </div>
        <FieldText
          label="1.12 What is the project’s main type of activity?"
          value={mainTypeOfActivity}
          customClass="full  not-out eval__gen-field textarea"
        />
        <FieldText
          label="1.13 What is the average tree density (trees/ha) in the project zone?"
          value={treeDensity}
          customClass="half eval__gen-field most-text"
        />
        <div className="eval__gen-checkboxs">
          <span>1.14 Is the tree density relatively homogeneous over the entire project zone?</span>
          <CustomCheckbox
            type="checkbox"
            onChange={() => {}}
            value="yes"
            title="yes"
            description=""
            key="yes"
            customClass={JSON.parse(isRelativelyHomogeneous) ? 'eval__checkbox' : 'eval__checkbox not-use'}
            checked={JSON.parse(isRelativelyHomogeneous)}
          />
          <CustomCheckbox
            type="checkbox"
            onChange={() => {}}
            value="no"
            title="no"
            description=""
            key="no"
            customClass={!JSON.parse(isRelativelyHomogeneous) ? 'eval__checkbox' : 'eval__checkbox not-use'}
            checked={!JSON.parse(isRelativelyHomogeneous)}
          />
        </div>
        <FieldText
          label="1.15 Describe the positive impacts of the project activities on ecosystems, biodiversity and local livelihoods."
          value={positiveImpactsDescription}
          customClass="full not-out eval__gen-field textarea"
        />
        <div className="eval__wrapper-btns">
          <Button className="cancel" onClick={() => setStep(0)}>Back </Button>
          <Button onClick={() => setStep(2)}>Next</Button>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
