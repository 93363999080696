import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import StepsTabs from './StepsTabs/StepsTabs';
import HeaderSteps from './HeaderSteps';
import Button from '../../generic/Button';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import EvaluatePage from './Steps/EvaluatePage/EvaluatePage';
import GeneralInfo from './Steps/GeneralInfo';
import ProjectActivity from './Steps/ProjectActivity';
import LandInfo from './Steps/LandInfo';
import Laf from './Steps/Laf';
import Footer from '../../layouts/Footer';
import ContactInfo from './Steps/ContactInfo';
import {
  getRequestProjectById,
  handleResponseStatus,
  sendRequest,
} from '../../utils/api-request';
import getConfig from '../../config';
import Loader from '../../components/Loader';
import CustomPopup from '../../generic/CustomPopup';
import getCarbonForm from '../../services/getCarbonForm';
import { getJSONFileFromIpfs, initIPFS } from '../../state/ipfs';
import Risks from './Steps/Risks';

const Evaluate = () => {
  const [step, setStep] = useState(0);
  const [projectData, setProjectData] = useState({});
  const [carbonFormData, setCarbonFormData] = useState({});
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const history = useHistory();
  const { indexerApi } = getConfig();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const setData = async (data) => {
    const ipfs = await initIPFS();
    const token = data?.hits?.hits?.length && data?.hits?.hits[0]._source;
    const fields = data?.hits?.hits?.length && data?.hits?.hits[0].fields;
    setIsLoading(true);
    if (!token && !fields) {
      setIsLoading(false);
      return setShowError(true);
    }
    const cidCarbon = token?.carbonApplication?.cid;
    if (!cidCarbon) {
      setIsLoading(false);
      return setShowError(true);
    }
    const carbonFormInfo = await getJSONFileFromIpfs(ipfs, cidCarbon);
    const carbonFormPrivateInfo = await getCarbonForm(id);
    setCarbonFormData({ carbonFormInfo, carbonFormPrivateInfo });
    setProjectData(token);
    setIsLoading(false);
  };

  const initialize = async () => {
    const { statusCode, result } = await sendRequest(indexerApi, 'POST', JSON.stringify(getRequestProjectById(id)));
    handleResponseStatus(
      statusCode,
      () => setData(result),
      () => setShowError(true),
    );
  };

  useEffect(() => {
    (async () => {
      await initialize();
    })();
  }, []);

  if (isLoading) {
    return (
      <div className="loader__wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className="eval">
      <HeaderSteps />
      <div className="eval__wrapper">
        <div className="eval__title">
          <span>{projectData.name}</span>
          <div className="eval__wrapper-btn">
            <Button
              className="eval__btn"
              onClick={() => {
                history.goBack();
              }}
            >
              <CloseIcon />
              <span>Cancel</span>
            </Button>
          </div>
        </div>
        <div className={step && 'eval__container'}>
          {!!step && <StepsTabs step={step} setStep={setStep} />}
          <EvaluatePage step={step} setStep={setStep} projectData={projectData} carbonFormData={carbonFormData} />
          <GeneralInfo step={step} setStep={setStep} data={carbonFormData} />
          <ProjectActivity step={step} setStep={setStep} data={carbonFormData} />
          <LandInfo step={step} setStep={setStep} data={carbonFormData} />
          <Laf step={step} setStep={setStep} data={carbonFormData} />
          <Risks step={step} setStep={setStep} data={carbonFormData} />
          <ContactInfo step={step} setStep={setStep} data={carbonFormData} />
        </div>
      </div>
      {showError && (
        <CustomPopup
          widthParam="wrapper-popup"
          setShowPopUp={() => {
            setShowError(false);
            history.push('/');
          }}
        >
          <div className="leakage__popup">
            <p className="leakage__popup-title">
              Oops!
            </p>
            <p className="leakage__popup-desc">
              Something goes wrong!
            </p>
            <Button
              className="leakage__popup-btn"
              onClick={() => {
                setShowError(false);
                history.push('/');
              }}
            >
              <span>Ok</span>
            </Button>
          </div>
        </CustomPopup>
      )}
      <Footer customClass="center" />
    </div>
  );
};

export default Evaluate;
