/* eslint-disable no-undef */
export default function getConfig() {
  const ipfsGateway = process.env.REACT_APP_INFURA_GATEWAY;
  let config = {
    projectDurationType: 'year',
    backendApi: process.env.REACT_APP_BACKEND,
    indexerApi: 'https://indexer-dev.openforestprotocol.org/projects',
    networkId: 'testnet',
    ipfsGateway,
    nodeUrl: 'https://rpc.testnet.near.org',
    walletUrl: 'https://testnet.mynearwallet.com',
    helperUrl: 'https://helper.testnet.near.org',
    explorerUrl: 'https://explorer.testnet.near.org',
    explorerOFPUrl: 'https://atlas-dev.openforestprotocol.org',
    contractName: ['dev_v1_nft.openforest.testnet', 'dev_v1_reward.openforest.testnet', 'dev_v1_occ.openforest.testnet'],
    GAS: '200000000000000',
    DEFAULT_NEW_ACCOUNT_AMOUNT: '5',
    contractMethods: {
      changeMethods: ['storage_deposit', 'storage_deposit_of', 'add_project_settings', 'update_project_carbon'],
      viewMethods: ['get_profile', 'get_protocol_info', 'get_current_project_settings', 'storage_balance_bounds', 'get_reward_pool', 'storage_balance_of'],
    },
  };
  if (process.env.REACT_APP_ENV === 'test') {
    config = {
      ...config,
      contractName: ['test_v1_nft.openforest.testnet', 'test_v1_reward.openforest.testnet', 'occ.openforest.testnet'],
      projectDurationType: 'day',
      explorerOFPUrl: 'https://atlas-test.openforestprotocol.org',
      backendApi: process.env.REACT_APP_BACKEND,
      indexerApi: 'https://indexer-test.openforestprotocol.org/projects',
    };
  }

  if (process.env.REACT_APP_ENV === 'prod') {
    config = {
      ...config,
      contractName: ['ofpcore.near', 'opntoken.near', 'occtoken.near'],
      projectDurationType: 'year',
      backendApi: process.env.REACT_APP_BACKEND,
      indexerApi: 'https://indexer.openforestprotocol.org/projects',
      networkId: 'mainnet',
      nodeUrl: 'https://rpc.mainnet.near.org',
      walletUrl: 'https://app.mynearwallet.com',
      helperUrl: 'https://helper.mainnet.near.org',
      explorerUrl: 'https://explorer.mainnet.near.org',
      explorerOFPUrl: 'https://atlas.openforestprotocol.org',
    };
  }

  return config;
}
