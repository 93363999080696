import jwt from 'jsonwebtoken';
import loginUser from '../services/loginUser';
import { storageKeys } from '../utils/constants';
import { encryptFunc } from '../utils/encode';

export const checkingLogin = (account, history, handleFunc = () => {}, login, update) => {
  (async () => {
    if (localStorage.getItem(storageKeys.isSignIn)) {
      localStorage.removeItem(storageKeys.isSignIn);
      update('loading', true);
      const { accountId } = account;
      const iterableKeys = Object.keys(localStorage);
      const keyCurrent = iterableKeys.find((el) => el.includes(`near-api-js:keystore:${accountId}`));
      const publicKey = localStorage.getItem(keyCurrent);
      const register = await loginUser(encryptFunc({ account: accountId, secret: publicKey })).catch((error) => {
        const failedToGetAdmin = 10050;
        if (error.response.data.status === failedToGetAdmin) {
          return update('app.errors', { text: 'no admin rights' });
        }
        return false;
      });
      if (register) {
        localStorage.setItem(storageKeys.token, register.data.token);
        if (login) {
          update('loading', false);

          return handleFunc();
        }
        update('loading', false);
        return history.push('/');
      }
    }
  })();
};

export const expireToken = (token) => {
  const decoded = jwt.decode(token);
  const dateNow = Date.now();
  const expireDate = Math.round(new Date(decoded?.ExpiredAt).getTime());
  return expireDate < dateNow;
};
