import uint8arrays from 'uint8arrays';
import ipfsClient from 'ipfs-http-client';

export const initIPFS = async () => {
  try {
    const projectId = process.env.REACT_APP_INFURA_PROJECT_ID;
    const secretId = process.env.REACT_APP_INFURA_SECRET_KEY;

    const secret = Buffer.from(`${projectId}:${secretId}`).toString('base64');

    const options = {
      host: 'ipfs.infura.io',
      port: 5001,
      protocol: 'https',
    };
    if (projectId && secretId) options.headers = { Authorization: `Basic ${secret}` };

    const ipfs = ipfsClient(options);

    if (!ipfs) throw new Error();
    return ipfs;
  } catch (e) {
    console.log(e);
    // update('app.errors', { type: 1001, text: 'IPFS connection error' });
  }
};

export const getJSONFileFromIpfs = async (client, cid) => {
  let file = '';
  try {
    for await (const chunk of client.cat(cid)) {
      file += uint8arrays.toString(chunk);
    }
    file = JSON.parse(file);
    return file;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getFilesFromDirectory = async (client, cid) => {
  const updateArray = [];
  try {
    for await (const file of client.get(cid)) {
      // eslint-disable-next-line no-continue
      if (!file.content) continue;
      updateArray.push({ path: file.path.split('/')[1] });
      const content = [];
      for await (const chunk of file.content) {
        content.push(chunk);
      }
    }
  } catch (e) {
    console.log(e);
  }
  return updateArray;
};
export const getFilesFromDirWithContent = async (client, cid, signal) => {
  const updateArray = [];
  for await (const file of client.get(cid, { signal })) {
    // eslint-disable-next-line no-continue
    if (!file.content) continue;
    const content = [];
    for await (const chunk of file.content) {
      content.push(chunk);
    }
    updateArray.push({ path: file.path.split('/')[1], content });
  }
  return updateArray;
};
