import { useHistory, useParams } from 'react-router';
import {
  useEffect, useState, useContext,
} from 'react';
import * as nearAPI from 'near-api-js';
import getConfig from '../config';
import {
  getRequestProjectById, handleResponseStatus, sendRequest,
} from '../utils/api-request';
import { getContract, methodsNames } from '../utils/near-utils';
import { GAS } from '../state/near';
import { depositValues } from '../utils/constants';
import { appStore } from '../state/app';
import { helperSettingForBunchTransaction } from '../utils/helpers';
import sendOrganistionStatus from '../services/sendOrganistionStatus';
import sendRejectCarbon from '../services/sendRejectCarbon';

const useLeakage = (typeLeakage = 'leakage') => {
  const { indexerApi, contractMethods } = getConfig();

  const { state } = useContext(appStore);
  const { account } = state;
  const history = useHistory();
  const [zonesArray, setZonesArray] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [showError, setShowError] = useState(false);
  const { connect, keyStores, WalletConnection } = nearAPI;
  const { nodeUrl, walletUrl, helperUrl, explorerUrl, networkId, contractName } = getConfig();
  const { storage_deposit, update_project_carbon } = methodsNames;

  const connectionConfig = {
    keyStore: new keyStores.BrowserLocalStorageKeyStore(),
    nodeUrl,
    walletUrl,
    helperUrl,
    explorerUrl,
    networkId,
  };
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const setData = async (data) => {
    const token = data?.hits?.hits?.length && data?.hits?.hits[0]._source;
    const fields = data?.hits?.hits?.length && data?.hits?.hits[0].fields;
    setIsLoading(true);
    if (!token && !fields) {
      setIsLoading(false);
      return setShowError(true);
    }
    if (token?.subZones && token?.subZones.length > 0) {
      let arrayZones;
      if (typeLeakage === 'edit' && token?.leakage?.zones) {
        arrayZones = token.subZones.map((el, index) => ({
          id: el.subId,
          value: token.leakage.zones.find((zone) => zone.id === el.subId)?.value,
          name: `MZ ${index + 1}`,
        }));
      } else {
        arrayZones = token.subZones.map((el, index) => ({
          id: el.subId,
          value: '',
          name: `MZ ${index + 1}`,
        }));
      }
      setZonesArray(arrayZones);
    }
    setProjectData(token);
    setIsLoading(false);
  };

  const callUpdateMethod = async (path, NotIsDeny, info, comment = '') => {
    const { walletConnection, signerPublicKey, sender, nonce, recentBlockHash } = await helperSettingForBunchTransaction(connect, connectionConfig, WalletConnection, account);
    const contractOcc = getContract(account, contractMethods, 2);
    const valueDepositOCC = await contractOcc.storage_balance_bounds();
    const isRegister = await contractOcc.storage_balance_of({ account_id: info ? info?.accountId : account?.accountId });

    const storageDepositActionOCC = [
      nearAPI.transactions.functionCall(storage_deposit, { account_id: info?.accountId }, GAS, valueDepositOCC.min),
    ];
    const updateProjectCarbon = [
      nearAPI.transactions.functionCall(update_project_carbon, { project_id: id,
        carbon: NotIsDeny ? 'simple' : null,
        reference: `/ipfs/${path}`,
        prev_reference: `${projectData.cid}` }, GAS, depositValues.one),
    ];
    const registerAccountTransaction = nearAPI.transactions.createTransaction(
      sender,
      signerPublicKey,
      contractName[0],
      nonce,
      updateProjectCarbon,
      recentBlockHash,
    );
    const registerAccountOCC = nearAPI.transactions.createTransaction(
      sender,
      signerPublicKey,
      contractName[2],
      nonce,
      storageDepositActionOCC,
      recentBlockHash,
    );
    if (typeLeakage === 'leakage') {
      localStorage.setItem(id, JSON.stringify({ project_operator_id: info?.ProjectOperatorId, approved: NotIsDeny, admin_comment: comment }));
      if (!NotIsDeny) {
        localStorage.setItem('rejectBody', JSON.stringify({ project_id: id }));
      }
    }
    if (!isRegister && NotIsDeny && typeLeakage === 'leakage') {
      await walletConnection.requestSignTransactions([registerAccountTransaction, registerAccountOCC]);
    } else await walletConnection.requestSignTransactions([registerAccountTransaction]);
  };

  const initialize = async () => {
    const { statusCode, result } = await sendRequest(indexerApi, 'POST', JSON.stringify(getRequestProjectById(id)));
    handleResponseStatus(
      statusCode,
      () => setData(result),
      () => setShowError(true),
    );
  };

  useEffect(() => {
    (async () => {
      await initialize();
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const params = (new URL(document.location)).searchParams;
      const hashParam = params.get('errorCode');
      const hashParamAffirm = params.get('transactionHashes');
      if (hashParamAffirm) {
        const currentCarbonProject = localStorage.getItem(id);
        const rejectBody = localStorage.getItem('rejectBody');
        if (typeLeakage === 'leakage' && currentCarbonProject) {
          await sendOrganistionStatus(currentCarbonProject);
          localStorage.removeItem(id);
        }
        if (rejectBody) {
          await sendRejectCarbon(rejectBody);
          localStorage.removeItem('rejectBody');
        }
        history.push('/');
      } else if (hashParam) {
        setShowError(true);
        localStorage.removeItem(id);
      }
    })();
  }, []);
  return {
    zonesArray,
    projectData,
    isLoading,
    setIsLoading,
    setZonesArray,
    callUpdateMethod,
    showError,
    setShowError,
  };
};
export default useLeakage;
