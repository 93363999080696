import React from 'react';

const Failed = ({ active }) => {
  const fillColor = active ? '#329D9C' : '#CECECE';
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0003 21C6.95386 21.0024 3.3048 18.5659 1.75597 14.8276C0.207138 11.0893 1.06384 6.78606 3.9263 3.92598C6.4536 1.39868 10.1372 0.411662 13.5896 1.33672C17.0419 2.26177 19.7385 4.95836 20.6636 8.41072C21.5886 11.8631 20.6016 15.5467 18.0743 18.074C16.2018 19.955 13.6545 21.0086 11.0003 21ZM3.0003 11.172C3.04762 15.5732 6.64141 19.1095 11.0428 19.086C15.4443 19.0622 18.9998 15.4875 18.9998 11.086C18.9998 6.68449 15.4443 3.10975 11.0428 3.08598C6.64141 3.06244 3.04762 6.59874 3.0003 11V11.172ZM8.4093 15L7.0003 13.59L9.5903 11L7.0003 8.40998L8.4103 6.99998L11.0003 9.58998L13.5903 6.99998L15.0003 8.40998L12.4103 11L15.0003 13.59L13.5913 15L11.0003 12.41L8.4103 15H8.4093Z"
        fill="#CDCECE"
      />
    </svg>
  );
};

export default Failed;
