import React, { useState } from 'react';
import Trash from '../../../assets/Trash.svg';
import Button from '../../../generic/Button';
import Edit from '../../../assets/Edit';
import CustomSwitch from '../../../generic/CustomSwitch';
import { permissionsModalTypes } from '../../../utils/constants';

const TableItemPermissions = ({ setModalState }) => {
  const [checked, onChangeSwitch] = useState(false);
  return (
    <div className="valitator-item__container">
      <div className="valitator-item__box">
        <div className="valitator-item__24">
          <div className="valitator-item__main-text">email</div>
        </div>
        <div className="valitator-item__19">
          <div className="valitator-item__main-text">
            <label htmlFor="small-radius-switch">
              <CustomSwitch checked={checked} onChange={onChangeSwitch} />
            </label>
          </div>
        </div>
        <div className="valitator-item__19">
          <div className="valitator-item__main-text">test</div>
        </div>
        <div className="valitator-item__19">
          <div className="valitator-item__main-text">test</div>
        </div>
        <div className="valitator-item__19">
          <div className="valitator-item__main-text">test</div>
        </div>
      </div>
      <div className="edit">
        <Edit active={false} />
      </div>

      <Button className="trash" onClick={() => setModalState({ show: true, type: permissionsModalTypes.removing })}>
        <img src={Trash} alt="trashIcon" />
      </Button>
    </div>
  );
};

export default TableItemPermissions;
