// These values need for information about type  in property type in carbonParams
// ForestType values
// const ForestTypeTropicalSubtropical = 0;
// const ForestTypeTemperate = 1;
// const ForestTypeBoreal = 2;
// ///TreeSpecies values
// const TreeSpeciesConiferous  = 0;
// const TreeSpeciesBroadleaf = 1;
// const TreeSpeciesForestTundra = 2;
// const TreeSpeciesMixedConiferousAndBroadleaf = 3;
// const TreeSpeciesPines = 4;

export const carbonParams = [{ value: 'Tropical & subtropical - Pines', label: 'Tropical & subtropical - Pines', type: { type: 0, tree: 4 } },
  { value: 'Tropical & subtropical - Broadleaf', label: 'Tropical & subtropical - Broadleaf', type: { type: 0, tree: 1 } },
  { value: 'Temperate - Coniferous', label: 'Temperate - Coniferous', type: { type: 1, tree: 0 } },
  { value: 'Temperate - Broadleaf', label: 'Temperate - Broadleaf', type: { type: 1, tree: 1 } },
  { value: 'Boreal - Forest tundra', label: 'Boreal - Forest tundra', type: { type: 2, tree: 2 } },
  { value: 'Boreal - Mixed coniferous & broadleaf', label: 'Boreal - Mixed coniferous & broadleaf', type: { type: 2, tree: 3 } },
  { value: 'Boreal - Coniferous', label: 'Boreal - Coniferous', type: { type: 2, tree: 0 } },
  { value: 'Boreal - Broadleaf', label: 'Boreal - Broadleaf', type: { type: 2, tree: 1 } }];

export const risksParams = ['0 - Not applicable', '1 - Low probability', '2 - Medium probability', '3 - High probability'];
