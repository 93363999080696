import React from 'react';
import Button from '../../generic/Button';

const ActionConfirmation = ({ question, onAccept = () => {}, onDecline = () => {} }) => (
  <div className="confirmation__container">
    <div className="confirmation__text-container">
      <span className="confirmation__question">{question}</span>
    </div>
    <div className="confirmation__buttons">
      <Button className="confirmation__no" onClick={onDecline}>
        <span className="confirmation__button-text">No</span>
      </Button>
      <Button type="submit" className="confirmation__yes" onClick={onAccept}>
        <span className="confirmation__button-text">Yes</span>
      </Button>
    </div>
  </div>
);

export default ActionConfirmation;
