import React from 'react';
import { Route, Switch } from 'react-router';
import RouteWithSubRoutes from './RouteWithSubRoutes';

const RenderRoutes = (props) => {
  const { routes } = props;
  return (
    <>
      <Switch>
        {routes.map((route) => (
          <RouteWithSubRoutes key={route.key + route.path} exact={route?.exact} component={route.component} path={route.path} routes={route?.routes} />
        ))}
        <Route component={() => <h1>Page not found!</h1>} />
      </Switch>
    </>
  );
};

export default RenderRoutes;
