import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as nearAPI from 'near-api-js';
import { appStore } from '../../state/app';
import { contractMethods, GAS } from '../../state/near';
import { storageKeys, ValidationConstants } from '../../utils/constants';
import Button from '../../generic/Button';
import CustomInput from '../../generic/CustomInput';
import getConfig from '../../config';
import { getContract, methodsNames } from '../../utils/near-utils';
import { helperSettingForBunchTransaction } from '../../utils/helpers';

const ValidatorRegistrationForm = ({ setShowModal }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { state } = useContext(appStore);
  const { account } = state;
  const { nodeUrl, walletUrl, helperUrl, explorerUrl, networkId, contractName } = getConfig();
  const { register_account_with_permissions, storage_deposit } = methodsNames;

  const onSubmit = async (data) => {
    const { connect, keyStores, WalletConnection } = nearAPI;
    const connectionConfig = {
      keyStore: new keyStores.BrowserLocalStorageKeyStore(),
      nodeUrl,
      walletUrl,
      helperUrl,
      explorerUrl,
      networkId,
    };
    const { walletConnection, signerPublicKey, sender, nonce, recentBlockHash } = await helperSettingForBunchTransaction(connect, connectionConfig, WalletConnection, account);
    const contract = getContract(account, contractMethods, 0);
    const contractOpn = getContract(account, contractMethods, 1);
    const contractOcc = getContract(account, contractMethods, 2);
    const valueDeposit = await contract.storage_balance_bounds();
    const valueDepositOPN = await contractOpn.storage_balance_bounds();
    const valueDepositOCC = await contractOcc.storage_balance_bounds();
    const storageDepositAction = [
      nearAPI.transactions.functionCall(storage_deposit, { account_id: data.near_account_id }, GAS, valueDeposit.min),
    ];
    const nearDeposit = nearAPI.utils.format.parseNearAmount('1');
    const storageDepositActionDub = [
      nearAPI.transactions.functionCall(storage_deposit, { account_id: data.near_account_id }, GAS, valueDepositOPN.min),
    ];
    const storageDepositActionOCC = [
      nearAPI.transactions.functionCall(storage_deposit, { account_id: data.near_account_id }, GAS, valueDepositOCC.min),
    ];
    const registerAccountAction = [
      nearAPI.transactions.functionCall(
        register_account_with_permissions,
        {
          account_id: data.near_account_id,
          permissions: { vesting: true },
        },
        GAS,
        nearDeposit,
      ),
    ];

    const storageDepositTransaction = nearAPI.transactions.createTransaction(
      sender,
      signerPublicKey,
      contractName[0],
      nonce,
      storageDepositAction,
      recentBlockHash,
    );
    const storageDepositDub = nearAPI.transactions.createTransaction(
      sender,
      signerPublicKey,
      contractName[1],
      nonce,
      storageDepositActionDub,
      recentBlockHash,
    );
    const registerAccountOCC = nearAPI.transactions.createTransaction(
      sender,
      signerPublicKey,
      contractName[2],
      nonce,
      storageDepositActionOCC,
      recentBlockHash,
    );
    const registerAccountTransaction = nearAPI.transactions.createTransaction(
      sender,
      signerPublicKey,
      contractName[1],
      nonce,
      registerAccountAction,
      recentBlockHash,
    );
    // order of transactions is necessary
    await walletConnection.requestSignTransactions([storageDepositTransaction, storageDepositDub, registerAccountOCC, registerAccountTransaction]);

    localStorage.setItem(storageKeys.toCreateValidatorData, JSON.stringify(data));
  };

  return (
    <>
      <form className="validator-form">
        <span className="title">Register Validator</span>
        <CustomInput
          type="email"
          label="Email"
          name="email"
          register={() => register('email', {
            required: 'Email is required.',
            pattern: {
              value: ValidationConstants.EMAIL,
              message: 'Please enter a valid email address.',
            },
          })}
          error={errors.email}
          onChange={(e) => { setValue('email', e.target.value); }}
          placeholder="Email"
        />

        <CustomInput
          type="text"
          label="NEAR account ID"
          register={() => register('near_account_id', {
            required: 'NEAR account ID is required.',
            pattern: {
              value: ValidationConstants.NEAR_ACCOUNT_ID,
              message: 'Please enter an id account in the format username.near.',
            },
          })}
          error={errors.near_account_id}
          onChange={(e) => { setValue('near_account_id', e.target.value); }}
          name="near_account_id"
          placeholder="NEAR account ID"
        />

        <div className="submit-container">
          <Button className="cancel" onClick={() => setShowModal(false)}>
            <span className="button-text">Cancel</span>
          </Button>
          <Button type="submit" className="submit" onClick={handleSubmit(onSubmit)}>
            <span className="button-text">Register Validator</span>
          </Button>
        </div>
      </form>
    </>
  );
};

export default ValidatorRegistrationForm;
