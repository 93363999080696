import React from 'react';
import Info from '../../assets/Info.svg';
import { permissionsTooltips } from '../../utils/table-constants';
import CustomTooltip from '../CustomTooltip';

const ColumnNames = () => (
  <div className="table__column-names">
    <div className="table__24">
      <div className="table__column-name" data-for="custom-color" data-tip="tooltip">
        LIST OF USERS
      </div>
    </div>
    <div className="table__19">
      <div className="table__column-name">
        Smart contract management
        <img src={Info} className="table__info-img" data-for="smart-contract" alt="img" data-tip="smart-contract-tooltip" />
      </div>
    </div>
    <div className="table__19">
      <div className="table__column-name">
        Profile management
        <img
          src={Info}
          className="table__info-img"
          data-for="profile-management"
          data-tip="profile-management-tooltip"
          alt="img"
        />
      </div>
    </div>
    <div className="table__19">
      <div className="table__column-name">
        Change project settings
        <img src={Info} className="table__info-img" data-for="settings" data-tip="settings-tooltip" alt="img" />
      </div>
    </div>
    <div className="table__19">
      <div className="table__column-name">
        Carbon approval/canceling
        <img src={Info} className="table__info-img" data-for="carbon" data-tip="carbon-tooltip" alt="img" />
      </div>
    </div>
    {permissionsTooltips.map((el) => (
      <CustomTooltip id={el.id} message={el.message} key={el.id} />
    ))}
    a
  </div>
);

export default ColumnNames;
