import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router';
import HeaderSteps from '../Evaluate/HeaderSteps';
import Button from '../../generic/Button';
import arrow from '../../assets/ArrowRigth.svg';
import warning from '../../assets/warning.svg';
import EditableElement from './EditableElement';
import DownloadZone from '../../components/DownloadZone';
import { notMoreHundred } from '../../utils/helpers';
import Footer from '../../layouts/Footer';
import CustomPopup from '../../generic/CustomPopup';
import useLeakage from '../../hooks/useLeakage';
import Loader from '../../components/Loader';
import { initIPFS } from '../../state/ipfs';
import { carbonParams } from '../../constans/constans';

const EditLeakage = () => {
  const { projectData, zonesArray,
    isLoading, setZonesArray, callUpdateMethod,
    showError, setShowError, setIsLoading } = useLeakage('edit');
  const [defaultState, setDefaultState] = useState({ leakage: '', forestType: {}, rainfall: '', zones: [] });
  const [defaultNumber, setDefaultNumber] = useState('');
  const [isDenyCarbon, setIsDenyCarbon] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  const [selectValue, setSelectValue] = useState({});
  const validLeakage = (val) => {
    const result = notMoreHundred(val);
    setDefaultState({ ...defaultState, leakage: result });
  };
  const onSave = async () => {
    setIsLoading(true);
    const newCurrentData = { ...defaultState, leakage: `${defaultState.leakage}`, zones: zonesArray };
    const dataWithZones = { ...projectData.leakage, ...newCurrentData };
    const updatedData = { ...projectData, leakage: dataWithZones };
    const ipfs = await initIPFS();
    const { path } = await ipfs.add(JSON.stringify(updatedData));
    setIsLoading(false);
    await callUpdateMethod(path, projectData.leakage.carbonApprove);
  };
  useEffect(() => {
    if (Object.keys(projectData).length) {
      if (!projectData?.leakage) {
        return setShowError(true);
      }
      if (!projectData?.leakage?.carbonApprove) {
        return setIsDenyCarbon(true);
      }
      const typeOfForestArray = projectData?.leakage?.forestType;
      const carbonOptionType = carbonParams.find((el) => {
        const { tree, type } = el.type;
        if (tree === typeOfForestArray?.tree && type === typeOfForestArray?.type) {
          return el;
        }
        return null;
      });
      setSelectValue(carbonOptionType);
      setDefaultNumber(projectData?.leakage?.baselineDefaultValue);
      setDefaultState({ ...projectData?.leakage });
    }
  }, [projectData]);

  if (isLoading) {
    return (
      <div className="loader__wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className="edit-lec">
      <HeaderSteps />
      <div className="edit-lec__wrapper">
        <div className="edit-lec__title">
          <span>{projectData?.name}</span>
          <div className="edit-lec__wrapper-btn">
            <Button
              className="edit-lec__btn back"
              onClick={() => {
                setShowPopup(true);
              }}
            >
              <ReactSVG src={arrow} />
              <span>Back</span>
            </Button>
          </div>
        </div>
        <div className="edit-lec__block-wrapper">
          <div className="edit-lec__form">
            <div className="edit-lec__form-block">
              <span>Leakage (%)</span>
            </div>
            <EditableElement
              change={(e) => { validLeakage(e.target.value); }}
              value={defaultState?.leakage}
              name="leakage"
              typeEdit="inputWithCheck"
              defaultState={defaultState}
              titleCheckbox="Use default value (5%)"
              checkBoxValue={5}
              changeCheckbox={(e) => {
                if (e.target.checked) {
                  return validLeakage(5);
                }
              }}
              btnLabel="edit"
            />
          </div>
          { zonesArray.length > 0 && (
          <div className="edit-lec__block">
            <div className="edit-lec__form-block col">
              <div className="edit-lec__baseline-title">
                <span>Baseline (tCO2e/ha/y) </span>
                <DownloadZone
                  customClass="edit-lec__download-zone"
                  buttonText="Download Monitoring zones"
                  coordinate={projectData}
                  fileName={`ofp_${projectData.name}_allMonitoring`}
                />
              </div>
              <div className="edit-lec__baseline">
                <EditableElement
                  ccCInput="edit-lec__carbon-input edit-lec__lec-input"
                  label="Default value (tCO2e/ha/y)"
                  value={defaultState?.baselineDefaultValue}
                  change={(e) => {
                    setDefaultNumber(e.target.value);
                    setDefaultState({
                      ...defaultState,
                      baselineDefaultValue: e.target.value,
                    });
                  }}
                  name="defaultValue"
                  typeEdit="input"
                  defaultState={zonesArray}
                  btnLabel="edit"
                />
              </div>
            </div>
            <div className="edit-lec__block-zone">
              {zonesArray.map((el, index) => (
                <div className="edit-lec__baseline" key={`MZ ${index + 1}`}>
                  <EditableElement
                    ccCInput={`edit-lec__carbon-input edit-lec__lec-input ${zonesArray[index]?.value ? '' : 'newZone'} between`}
                    change={(e) => {
                      setZonesArray(zonesArray.map((zone, count) => {
                        if (index === count) {
                          return { ...zone, value: e.target.value };
                        }
                        return zone;
                      }));
                    }}
                    name={`MZ ${index + 1}`}
                    typeEdit="inputWithCheck"
                    defaultState={defaultState.zones}
                    titleCheckbox="Use default value"
                    checkBoxValue={defaultNumber}
                    value={zonesArray[index]?.value}
                    changeCheckbox={(e) => setZonesArray(zonesArray.map((zone, count) => {
                      if (index === count && e.target.checked) {
                        return { ...zone, value: e.target.value };
                      }
                      return zone;
                    }))}
                    btnLabel="edit"
                    label={`MZ ${index + 1}`}
                  />
                </div>
              ))}
            </div>

          </div>
          )}
          <div className="edit-lec__block">
            <div className="edit-lec__form-block">
              <span>Carbon parameters </span>
            </div>
            <div className="edit-lec__block mt-small">
              {selectValue?.value && (
              <EditableElement
                label="Forest type"
                change={(e) => {
                  setSelectValue(e);
                  setDefaultState({
                    ...defaultState,
                    forestType: e.type,
                  });
                }}
                name="forestType"
                typeEdit="select"
                defaultState={defaultState}
                btnLabel="edit"
                value={selectValue?.value}
                options={carbonParams}
              />
              )}
              <EditableElement ccCInput="edit-lec__carbon-input rainfall edit-lec__lec-input" label="Annual rainfall (mm/y)" change={(e) => setDefaultState({ ...defaultState, rainfall: e.target.value })} name="rainfall" typeEdit="input" value={defaultState?.rainfall} btnLabel="edit" />
            </div>
          </div>
          <div className="leakage__edit-lec-btn">
            <Button
              onClick={() => onSave()}
              className=""
            >
              <span>Save</span>
            </Button>
          </div>
        </div>
      </div>
      {showPopup && (
        <CustomPopup widthParam="edit-lec__popup-wrapper" setShowPopUp={() => setShowPopup(false)}>
          <div className="edit-lec__popup">
            <div className="edit-lec__popup-title-wrapper">
              <ReactSVG src={warning} />
              <h2 className="edit-lec__popup-title">
                Leave page?
              </h2>
            </div>

            <p className="edit-lec__popup-desc">
              Changes that you made may not be saved.
              {' '}
            </p>
            <div className="edit-lec__popup-btns">
              <Button
                onClick={() => {
                  setShowPopup(false);
                  history.goBack();
                }}
                className="cancel"
              >
                <span>Leave</span>
              </Button>
              <Button
                onClick={() => {
                  setShowPopup(false);
                }}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </CustomPopup>
      )}
      {showError && (
      <CustomPopup
        widthParam="wrapper-popup"
        setShowPopUp={() => {
          setShowPopup(false);
          history.push('/');
        }}
      >
        <div className="leakage__popup">
          <p className="leakage__popup-title">
            Oops!
          </p>
          <p className="leakage__popup-desc">
            Something goes wrong!
          </p>
          <Button
            className="leakage__popup-btn"
            onClick={() => {
              setShowError(false);
              history.push('/');
            }}
          >
            <span>Ok</span>
          </Button>
        </div>
      </CustomPopup>
      )}
      {isDenyCarbon && (
        <CustomPopup
          widthParam="wrapper-popup"
          setShowPopUp={() => history.push('/')}
        >
          <div className="leakage__popup">
            <p className="leakage__popup-desc">
              This project is denied and not edit.
            </p>
            <Button
              className="leakage__popup-btn"
              onClick={() => {
                history.push('/');
              }}
            >
              <span>Ok</span>
            </Button>
          </div>
        </CustomPopup>
      )}
      <Footer customClass="absolute-center" />
    </div>
  );
};

export default EditLeakage;
