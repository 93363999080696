/* eslint-disable no-undef */
import { projectTypes } from '../utils/constants';

export const getLocaleDateString = (date) => {
  if (date > 0) {
    return new Date(date / 1000000).toLocaleDateString();
  }
  return '-----';
};

export const getFullType = (type) => projectTypes.find((el) => el.value === type)?.title;

export const createRows = (projects) => projects?.map((el) => {
  const { name, developer, type, square, startTimeProject, region } = el._source;
  return {
    name,
    developer,
    type: getFullType(type),
    square,
    startTimeProject: getLocaleDateString(startTimeProject),
    region,
  };
});
export const concatItems = (items) => {
  if (items?.length === 1) {
    return items[0];
  }

  return items.map((type, index) => type.concat(index !== items?.length - 1 ? ', ' : ''));
};

export const checkVerifiedStatus = (status, callback) => {
  if (status === 'approve') {
    callback('Date of approval:');
  }

  if (status === 'reject') {
    callback('Date of rejection:');
  }
};

export const decodeBase64 = (str) => Buffer.from(str, 'base64').toString('ascii');
