import React, {
  useCallback, useContext, useEffect, useState, useRef,
} from 'react';
import { CSVLink } from 'react-csv';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash.debounce';
import getConfig from '../../config';
import { appStore } from '../../state/app';
import Loader from '../Loader';
import TableItem from '../Tabs/TableItem';
import { headersCSV } from '../../utils/constants';
import Button from '../../generic/Button';
import { createRows } from '../../helpers/main';
import SearchInput from '../SearchInput';
import TableColumnNames from '../TableColumnNames';
import { protocolInfoTableNames } from '../../utils/table-constants';
import {
  protocolInfoRequestBody, protocolInfoTotalHectares, sendRequest,
} from '../../utils/api-request';

const ProtocolInfo = () => {
  const [info, setInfo] = useState(null);
  const [projects, setProjects] = useState([]);
  const [amountProject, setAmountProject] = useState(0);
  const [sortParams] = useState({ key: 'startDateProject', value: 'desc' });
  const [csvData, setCsvData] = useState({ listOfProject: [],
    loading: false,
    isDownload: false });
  const [filterQuery, setFilterQuery] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [moreIsLoading, moreSetIsLoading] = useState(false);
  const [rows, setRows] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const { state } = useContext(appStore);
  const { account } = state;
  const defaultBody = protocolInfoRequestBody;
  const csvLink = useRef();
  const { indexerApi } = getConfig();

  const getProtocolInfo = async () => {
    if (account) {
      const { result } = await sendRequest(indexerApi, 'POST', JSON.stringify(protocolInfoTotalHectares));
      const { aggregations, hits } = result;
      const infoObject = { totalHa: aggregations?.sumHa?.value, totalProject: hits?.total?.value };
      setInfo(infoObject);
    }
  };

  const toFixedHa = (val) => {
    if (val) {
      return val.toFixed(3);
    } return '--';
  };

  const fetchProjects = async (filter, activeSort, from, allProjects) => {
    let body = {};
    if (filter?.length) {
      // eslint-disable-next-line no-undef
      const copyBody = structuredClone(defaultBody);
      copyBody.query.bool.must = [...copyBody.query.bool?.must, ...filter];
      body = copyBody;
    } else body = defaultBody;
    body.sort = [{ [activeSort.key]: activeSort.value }];
    body.from = from;
    body.size = allProjects ? info.totalProject : 30;
    const result = await sendRequest(indexerApi, 'POST', JSON.stringify(body));
    return result;
  };

  const filterByFilterQuery = async (params) => {
    setLoading(true);
    const { result } = await fetchProjects(params, sortParams, 0);
    if (result?.hits?.hits) {
      setAmountProject(result?.hits?.total?.value);
      setProjects(result?.hits?.hits);
      setRows(createRows(result?.hits?.hits));
    } else {
      setProjects('');
      setRows(createRows([]));
    }
    setLoading(false);
  };

  const setFilterWithDelay = useCallback(debounce(async (searParams) => {
    setLoading(true);
    if (!searParams) {
      setFilterQuery([]);
      await filterByFilterQuery([]);
      return;
    }
    const target = searParams?.toLowerCase();
    const filterParams = [{
      query_string: {
        fields: ['name', 'developer'],
        query: target,
        type: 'phrase_prefix',
        default_operator: 'or',
      },
    }];
    setFilterQuery(filterParams);
    await filterByFilterQuery(filterParams);
  }, 500), []);

  const downloadCSV = async () => {
    setCsvData({ ...csvData, loading: true });
    const { result } = await fetchProjects([], sortParams, 0, true);
    if (result?.hits?.hits) {
      setCsvData({ listOfProject: createRows(result?.hits?.hits), loading: false, isDownload: true });
    } else {
      setCsvData({ listOfProject: createRows([]), loading: false, isDownload: true });
    }
  };

  const loadMore = async () => {
    if (projects && !(projects.length >= amountProject)) {
      moreSetIsLoading(true);
      const { result } = await fetchProjects(filterQuery, sortParams, projects.length);
      if (result?.hits?.hits) {
        setAmountProject(result?.hits?.total?.value);
        setProjects([...projects, ...result?.hits?.hits]);
        setRows(createRows([...projects, ...result?.hits?.hits]));
      }
      moreSetIsLoading(false);
      setLoading(false);
    }
  };

  const { ref } = useInView({
    root: null,
    threshold: 0,
    onChange: (inView) => {
      if (inView) {
        loadMore();
      }
    },
  });
  useEffect(() => {
    setFilterWithDelay(searchValue);
  }, [searchValue]);

  useEffect(() => {
    getProtocolInfo();
  }, []);

  useEffect(() => {
    if (csvData.isDownload) {
      csvLink.current.link.click();
      setCsvData({ ...csvData, isDownload: false });
    }
  }, [csvData]);

  return (
    <>
      <span className="info__title">Protocol Info</span>
      <div className="info__top-block">
        <div className="info__stats">
          {!!info?.totalProject && (
            <span className="info__stats-stat">
              Total Projects:
              {' '}
              {info?.totalProject}
            </span>
          )}
          {!!info?.totalHa && (
            <span className="info__stats-stat">
              Total ha:
              {' '}
              {toFixedHa(+info?.totalHa) }
              {' '}
              ha
            </span>
          )}
        </div>
        {rows && (
          <div className="info__controls">
            <SearchInput
              placeholder="Search project by name or organisation "
              customClass="info__search"
              value={searchValue}
              onChange={setSearchValue}
            />
            <Button className="csv__button" onClick={() => downloadCSV()}>
              <span className="csv__title">
                {' '}
                {csvData.loading ? 'Loading CSV...' : 'Download CSV'}
              </span>
            </Button>
            <CSVLink
              data={csvData.listOfProject}
              headers={headersCSV}
              ref={csvLink}
              filename="Protocol Info"
            />
          </div>
        )}
      </div>
      <div className="table">
        <TableColumnNames names={protocolInfoTableNames} />
        {!projects && (
          <div className="table__boxes">
            <h3 className="table__boxes-info">Projects not found.</h3>
          </div>
        )}
        {!isLoading && !!projects?.length && projects?.map((el, index, array) => <TableItem cardRef={ref} source={el?._source} isLast={array.length - 1 === index} key={el?._id} />)}
        {isLoading && (
          <div className="table__loader-init">
            <Loader />
          </div>
        )}
        {moreIsLoading && (
          <div className="table__loader-more">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

export default ProtocolInfo;
