import React from 'react';
import { useHistory } from 'react-router';
import DownloadZone from '../DownloadZone';
import Button from '../../generic/Button';
import Pencil from '../../assets/Pencil';
import CustomTooltip from '../CustomTooltip';
import { getLocaleDateString } from '../../helpers/main';
import getConfig from '../../config';

const CarbonCardNew = ({ data, cardRef, isLast }) => {
  const ref = isLast ? { ref: cardRef } : {};

  const history = useHistory();
  const { fields } = data;
  const [mrv] = fields.mrvRang;
  const [submittedAtDate] = fields.submittedAtDate;
  const { explorerOFPUrl } = getConfig();
  return (
    <div className="carbonCard" {...ref}>
      <div className="carbonCard__text">
        <a href={`${explorerOFPUrl}/${data?.project_id}`} rel="noopener noreferrer" className="carbonCard__anchor">{data?.name}</a>
      </div>
      <div className="carbonCard__text">{data?.developer}</div>
      <div className="carbonCard__text">{data?.square}</div>
      <div className="carbonCard__text">{getLocaleDateString(+submittedAtDate)}</div>
      <div className="carbonCard__text">{mrv > 0 ? 'yes' : 'no'}</div>

      <div className="carbonCard__wrapper-zone">
        <DownloadZone
          buttonText="Download Zone"
          coordinate={data}
          fileName={`ofp_${data.name}_allMonitoring`}
        />
      </div>
      <div className="carbonCard__wrapper-btn">
        <Button className="carbonCard__btn" onClick={() => history.push(`/evaluate/${data?.project_id}`)}>
          Evaluate
        </Button>
      </div>
    </div>
  );
};

const CarbonCardApprove = ({ data, cardRef, isLast }) => {
  const ref = isLast ? { ref: cardRef } : {};
  const dataFor = `cardApprove${Math.floor(Math.random() * 1e9)}`;
  const { fields } = data;
  const history = useHistory();
  const [mrv] = fields.mrvRang;
  const [isNewZone] = fields.isNewZone;
  const [nextValidation] = fields.nextValidation;
  return (
    <div className={`carbonCard carbonCard__approve ${isNewZone && 'active'}`} {...ref}>
      <div className="carbonCard__text">
        {data?.name}
      </div>
      <div className="carbonCard__text">{data?.developer}</div>
      <div className="carbonCard__text">{data?.square}</div>
      <div className="carbonCard__text">{getLocaleDateString(nextValidation)}</div>
      <div className="carbonCard__text">{mrv > 0 ? 'yes' : 'no'}</div>
      <div className="carbonCard__wrapper-zone">
        <DownloadZone
          buttonText="Download Zone"
          coordinate={data}
          fileName={`ofp_${data.name}_allMonitoring`}
          customClass="carbonCard__approve-zone"
        />
      </div>
      <div className="carbonCard__wrapper-btn approve" data-tip data-for={dataFor} data-iscapture="true">
        <Button className="carbonCard__btn-approve" disabled={!data?.leakage?.comment}>
          Comment
        </Button>
        {data?.leakage?.comment && <CustomTooltip id={dataFor} arrowColor="transparent" customClass="carbonCard__tooltip " message={data?.leakage?.comment} />}
      </div>
      <div className="carbonCard__edit" onClick={() => history.push(`edit-leakage/${data.project_id}`)}>
        <span>Edit</span>
        <Pencil active className="settings__pencil" />
      </div>
    </div>
  );
};

const CarbonCardDenied = ({ data, cardRef, isLast }) => {
  const ref = isLast ? { ref: cardRef } : {};
  const dataFor = `cardApprove${Math.floor(Math.random() * 1e9)}`;
  const { fields } = data;
  const [mrv] = fields.mrvRang;
  const [deniedDate] = fields.deniedDate;

  return (
    <div className="carbonCard carbonCard__denied" {...ref}>
      <div className="carbonCard__text">
        {data?.name}
      </div>
      <div className="carbonCard__text">{data?.developer}</div>
      <div className="carbonCard__text">{data?.square}</div>
      <div className="carbonCard__text">{getLocaleDateString(deniedDate)}</div>
      <div className="carbonCard__text">{mrv > 0 ? 'yes' : 'no'}</div>
      <div className="carbonCard__wrapper-zone">
        <DownloadZone
          buttonText="Download Zone"
          coordinate={data}
          fileName={`ofp_${data.name}_allMonitoring`}
          customClass="carbonCard__denied-zone"
        />
      </div>
      <div className="carbonCard__wrapper-btn approve" data-tip data-for={dataFor} data-iscapture="true">
        <Button className="carbonCard__btn-denied">
          Comment
        </Button>
        <CustomTooltip id={dataFor} arrowColor="transparent" customClass="carbonCard__tooltip " message={data?.leakage?.comment} />
      </div>
    </div>
  );
};
const CarbonCard = ({ viewType, data, cardRef, isLast }) => (
  <div>
    {viewType === 'new' && <CarbonCardNew data={data} cardRef={cardRef} isLast={isLast} />}
    {viewType === 'approve' && <CarbonCardApprove data={data} cardRef={cardRef} isLast={isLast} />}
    {viewType === 'denied' && <CarbonCardDenied data={data} cardRef={cardRef} isLast={isLast} />}

  </div>
);

export default CarbonCard;
