import React from 'react';
import FieldText from '../FieldText';

const FieldBlock = ({ label, valArea, valInput }) => (
  <div className="eval__field-block">
    {label && <span className="eval__field-block-title">{label}</span>}
    <FieldText
      label=""
      value={valInput}
      customClass="half eval__gen-field"
    />
    <FieldText label="" value={valArea} customClass="full not-out eval__field-block-field textarea" />
  </div>
);

export default FieldBlock;
