import axios from 'axios';
import getConfig from '../config';
import { storageKeys } from '../utils/constants';

export const clientApi = () => {
  const getToken = localStorage.getItem(storageKeys.token);
  const header = getToken
    ? {
      Authorization: `Bearer ${getToken}`,
    }
    : {};
  const { backendApi } = getConfig();

  return axios.create({
    baseURL: backendApi,
    headers: {
      ...header,
      'Content-Type': 'application/json',
    },
  });
};
